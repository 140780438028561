import React, { useState, useEffect } from 'react';
import Modal from '../common/Modal';
import CustomScrollbar from '../common/CustomScrollbar';
import Button from '../common/FormFields/Button';
import Tags from '../common/Tags';
import Checkbox from '../common/FormFields/Checkbox';
import CustomSelect from '../common/FormFields/Select';
import {
  ABO,
  Rhd,
  ALL_BLOOD_TYPES,
  BLOOD_TITER,
  DONOR_STATUS,
  APP_USER_STATUS,
  SMS_STATUS,
} from '../../constants/pushMessages';
import {
  bloodTypesToTags,
  filtersToTags,
  filterDateToTags
} from '../../services/helpers';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { DateTime } from "luxon";
import Datepicker from '../common/FormFields/Datepicker';

export const FilterSettingsModal = (props) => {
    const {
        filterSettingsModalIsOpen,
        toggleFilterSettingsModal,
        selectedFilters,
        setSelectedFilters,
        refreshFilters,
    } = props;
    const { t } = useTranslation();
    const [datepickerQuarantineDate, setDatepickerQuarantineDate] = useState(new Date());
    const [datepickerNextAppointmentDate, setDatepickerNextAppointmentDate] = useState(new Date());
    const [quarantineDueDate, setQuarantineDueDate] = useState([]);
    const [nextAppointmentDate, setNextAppointmentDate] = useState([]);
    const [allSmsStatuses, setAllSmsStatuses] = useState(filtersToTags(SMS_STATUS, t, 'common.smsStatus.'));
    const [selectedSmsStatus, setSelectedSmsStatus] = useState(allSmsStatuses[1]);
    const [allSmsStatusesIsChecked, setAllSmsStatusesIsChecked] = useState(true);

    const [allAppUserStatuses, setAllAppUserStatuses] = useState(filtersToTags(APP_USER_STATUS, t, 'common.appUserStatus.'));
    const [selectedAppUserStatus, setSelectedAppUserStatus] = useState(allAppUserStatuses[0]);
    const [allAppUserStatusesIsChecked, setAllAppUserStatusesIsChecked] = useState(true);

    const [allDonorStatuses, setAllDonorStatuses] = useState(filtersToTags(DONOR_STATUS, t, 'common.donorStatus.'));
    const [selectedStatus, setSelectedStatus] = useState(allDonorStatuses[0]);
    const [allBloodStatusesIsChecked, setAllBloodStatusesIsChecked] = useState(true);

    const [allTiters, setAllTiters] = useState(filtersToTags(BLOOD_TITER, t, 'configuration.settings.titer.'));
    const [selectedTiter, setSelectedTiter] = useState(allTiters[2]);
    const [allBloodTitersIsChecked, setAllBloodTitersIsChecked] = useState(true);

    const [selectedRhesus, setSelectedRhesus] = useState(Rhd[0]);
    const [selectedBloodGroup, setSelectedBloodGroup] = useState(ABO[0]);
    const [allBloodTypesIsChecked, setAllBloodTypesIsChecked] = useState(true);

    const [selectedBloodTypes, setSelectedBloodTypes] = useState([]);
    const [selectedBloodTiters, setSelectedBloodTiters] = useState([]);
    const [selectedDonorStatuses, setSelectedDonorStatuses] = useState([]);
    const [selectedAppUserStatuses, setSelectedAppUserStatuses] = useState([]);
    const [selectedSmsStatuses, setSelectedSmsStatuses] = useState([]);

    i18n.on("languageChanged", () => {
      setAllTiters(filtersToTags(BLOOD_TITER, t, 'configuration.settings.titer.'));
      setAllDonorStatuses(filtersToTags(DONOR_STATUS, t, 'common.donorStatus.'));
      setAllAppUserStatuses(filtersToTags(APP_USER_STATUS, t, 'common.appUserStatus.'));
      setAllSmsStatuses(filtersToTags(SMS_STATUS, t, 'common.smsStatus.'));
    });

    useEffect(() => {
      setSelectedSmsStatus(allSmsStatuses[1]);
      setSelectedAppUserStatus(allAppUserStatuses[0]);
      setSelectedStatus(allDonorStatuses[0]);
      setSelectedTiter(allTiters[2]);
    }, [allSmsStatuses, allAppUserStatuses, allDonorStatuses, allTiters]);

    useEffect(() => {
      if (filterSettingsModalIsOpen && selectedFilters) {
        setSelectedBloodTypes(bloodTypesToTags(selectedFilters.donorTypes));
        setQuarantineDueDate(filterDateToTags(selectedFilters.quarantineDue || []));
        setNextAppointmentDate(filterDateToTags(selectedFilters.nextAppointment || []));
        setSelectedBloodTiters(filtersToTags(selectedFilters.bloodTiters, t, 'configuration.settings.titer.'));
        setSelectedDonorStatuses(filtersToTags(selectedFilters.donorStatuses, t, 'common.donorStatus.'));
        setSelectedAppUserStatuses(filtersToTags(selectedFilters.appUserStatuses, t, 'common.appUserStatus.'));
        setSelectedSmsStatuses(filtersToTags(selectedFilters.smsStatuses, t, 'common.smsStatus.'));
        setAllBloodTypesIsChecked(selectedBloodTypes.length === 8);
        setAllBloodTitersIsChecked(selectedBloodTiters.length === 3);
        setAllBloodStatusesIsChecked(selectedDonorStatuses.length === 3);
        setAllAppUserStatusesIsChecked(selectedAppUserStatuses.length === 2);
        setAllSmsStatusesIsChecked(selectedSmsStatuses.length === 2);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterSettingsModalIsOpen]);

    useEffect(() => {
      if (selectedBloodTypes.length < 8) {
        setAllBloodTypesIsChecked(false);
      } else {
        setAllBloodTypesIsChecked(true);
      }
    }, [selectedBloodTypes]);

    useEffect(() => {
      if (selectedBloodTiters.length < 3) {
        setAllBloodTitersIsChecked(false);
      } else {
        setAllBloodTitersIsChecked(true);
      }
    }, [selectedBloodTiters]);

    useEffect(() => {
      if (selectedDonorStatuses.length < 3) {
        setAllBloodStatusesIsChecked(false);
      } else {
        setAllBloodStatusesIsChecked(true);
      }
    }, [selectedDonorStatuses]);

    useEffect(() => {
      if (selectedAppUserStatuses.length < 2) {
        setAllAppUserStatusesIsChecked(false);
      } else {
        setAllAppUserStatusesIsChecked(true);
      }
    }, [selectedAppUserStatuses]);

    useEffect(() => {
      if (selectedSmsStatuses.length < 2) {
        setAllSmsStatusesIsChecked(false);
      } else {
        setAllSmsStatusesIsChecked(true);
      }
    }, [selectedSmsStatuses]);

    const handleAllBloodTypesChange = () => {
      if (!allBloodTypesIsChecked) {
        setSelectedBloodTypes(bloodTypesToTags(ALL_BLOOD_TYPES));
      } else {
        setSelectedBloodTypes([]);
      }
    }
    const addBloodTypeTag = () => {
      const newTag = {
        label: `${selectedBloodGroup.label}${selectedRhesus.label}`,
        value: {rhesus: selectedRhesus.value, bloodType: selectedBloodGroup.value}
      };
      setSelectedBloodTypes([
        ...selectedBloodTypes,
        ...(!selectedBloodTypes.find(item => item.label === newTag.label) ? [newTag] : [])
      ]);
    };

    const handleAllBloodTitersChange = () => {
      if (!allBloodTitersIsChecked) {
        setSelectedBloodTiters(allTiters);
      } else {
        setSelectedBloodTiters([]);
      }
    }  
    const addBloodTiterTag = () => {
      setSelectedBloodTiters([
        ...selectedBloodTiters,
        ...(!selectedBloodTiters.find(item => item.label === selectedTiter.label) ? [selectedTiter] : [])
      ]);
    };

    const toTagDate = (date) => {
      const dateTime = DateTime.fromJSDate(new Date(date));
      const formattedDate = dateTime.toFormat('dd MMMM yyyy');
      return formattedDate
    }

    const addQuarantineDueDate = () => {
      setQuarantineDueDate([{label: toTagDate(datepickerQuarantineDate),value:toTagDate(datepickerQuarantineDate)}])
    }

    const addNextAppointmentDate = () => {
      setNextAppointmentDate([{label: toTagDate(datepickerNextAppointmentDate),value:toTagDate(datepickerNextAppointmentDate)}])
    }

    const handleAllDonorStatusesChange = () => {
      if (!allBloodStatusesIsChecked) {
        setSelectedDonorStatuses(allDonorStatuses);
      } else {
        setSelectedDonorStatuses([]);
      }
    }  
    const addDonorStatusTag = () => {
      setSelectedDonorStatuses([
        ...selectedDonorStatuses,
        ...(!selectedDonorStatuses.find(item => item.label === selectedStatus.label) ? [selectedStatus] : [])
      ]);
    };

    const handleAllAppUserStatusesChange = () => {
      if (!allAppUserStatusesIsChecked) {
        setSelectedAppUserStatuses(allAppUserStatuses);
      } else {
        setSelectedAppUserStatuses([]);
      }
    }  
    const addAppUserStatusTag = () => {
      setSelectedAppUserStatuses([
        ...selectedAppUserStatuses,
        ...(!selectedAppUserStatuses.find(item => item.label === selectedAppUserStatus.label) ? [selectedAppUserStatus] : [])
      ]);
    };

    const handleAllSmsStatusesChange = () => {
      if (!allSmsStatusesIsChecked) {
        setSelectedSmsStatuses(allSmsStatuses);
      } else {
        setSelectedSmsStatuses([]);
      }
    }  
    const addSmsStatusTag = () => {
      setSelectedSmsStatuses([
        ...selectedSmsStatuses,
        ...(!selectedSmsStatuses.find(item => item.label === selectedSmsStatus.label) ? [selectedSmsStatus] : [])
      ]);
    };

    const handleSave = () => {
      toggleFilterSettingsModal();
      setSelectedFilters({
        donorTypes: selectedBloodTypes.map((item) => item.value),
        bloodTiters: selectedBloodTiters.map((item) => item.value),
        donorStatuses: selectedDonorStatuses.map((item) => item.value),
        appUserStatuses: selectedAppUserStatuses.map((item) => item.value),
        smsStatuses: selectedSmsStatuses.map((item) => item.value),
        quarantineDue: quarantineDueDate.map((item) => item.value),
        nextAppointment: nextAppointmentDate.map((item) => item.value),
      });
    };
    
    const handleModalClose = () => {
      toggleFilterSettingsModal();
    };

    const handleRefreshFilters = () => {
      refreshFilters();
      setSelectedBloodTypes([]);
      setSelectedBloodTiters([]);
      setSelectedDonorStatuses([]);
      setSelectedAppUserStatuses([]);
      setSelectedSmsStatuses([]);
      setQuarantineDueDate([])
      setDatepickerQuarantineDate(new Date())
      setNextAppointmentDate([])
      setDatepickerNextAppointmentDate(new Date())
    };

    return (
        <>
        <Modal isModalOpen={ filterSettingsModalIsOpen } additionalClassName={'left'}
          handleModalClose={ handleModalClose } { ...props }
        >
          <div className="modal-header">
            <button className="modal-additional-icon" onClick={ handleRefreshFilters }>
              <span className="icon-refresh" />
            </button>
            <h2 className="modal-title">
              {t('msgs.filter_settings')}
            </h2>
            <button className="modal-close-right" onClick={ handleModalClose }>
              <span className="icon-close" />
            </button>
          </div>
  
          <CustomScrollbar className="modal-body modal-flex-wrapper">
            <section className="modal-content">
              <div className="form-group">
                <div className="inputs-group">
                  <div className="inputs-group-item">ABO</div>
                  <div className="inputs-group-item">
                    <CustomSelect
                      data={ ABO }
                      value={ selectedBloodGroup }
                      onChange={ setSelectedBloodGroup }
                      placeholder="Select"
                      additionalClassName="xs"
                    />
                  </div>
                  <div className="inputs-group-item">Rh(D)</div>
                  <div className="inputs-group-item">
                    <CustomSelect
                      data={ Rhd }
                      value={ selectedRhesus }
                      onChange={ setSelectedRhesus }
                      placeholder="Select"
                      additionalClassName="xs"
                    />
                  </div>
                  <div className="inputs-group-item d-flex flex-grow-1 justify-content-end">
                    <button className="btn-flat" 
                      onClick={addBloodTypeTag} 
                    >
                        +{t('configuration.settings.add')}
                    </button>
                  </div>
                </div>
                <div className='inputs-group form-tags'>
                  <Checkbox
                    label={t('configuration.settings.allBloodTypes')}
                    checked={allBloodTypesIsChecked}
                    onChangeValue={setAllBloodTypesIsChecked}
                    checkboxHandler={handleAllBloodTypesChange}
                  />
                </div>
                  <div className="form-tags">
                    {
                      selectedBloodTypes.length ?
                        <Tags 
                          additionalClass="inline" 
                          data={selectedBloodTypes} 
                          onDeleteTag={ setSelectedBloodTypes }
                        />
                      : <></>
                    }
                  </div>
              </div>
              <div className="form-group">
                <div className="inputs-group">
                  <div className="inputs-group-item">{t('configuration.settings.titer.title')}</div>
                  <div className="inputs-group-item">
                    <CustomSelect
                      data={ allTiters }
                      value={ selectedTiter }
                      onChange={ setSelectedTiter }
                      placeholder="Select"
                      additionalClassName="lg"
                    />
                  </div>
                  <div className="inputs-group-item d-flex flex-grow-1 justify-content-end">
                    <button 
                      className="btn-flat"
                      onClick={ addBloodTiterTag } 
                    >
                      +{t('configuration.settings.add')}
                    </button>
                  </div>
                </div>
                <div className='inputs-group form-tags'>
                  <Checkbox
                    label={t('configuration.settings.titer.allVariants')}
                    checked={ allBloodTitersIsChecked }
                    onChangeValue={ setAllBloodTitersIsChecked }
                    checkboxHandler={ handleAllBloodTitersChange }
                  />
                </div>
                <div className="form-tags">
                  {
                    selectedBloodTiters?.length ?
                      <Tags 
                        additionalClass="inline" 
                        data={ selectedBloodTiters } 
                        onDeleteTag={ setSelectedBloodTiters }
                      /> : 
                      <></>
                  }
                </div>
              </div> 
              <div className="form-group">
                <div className="inputs-group">
                  <div className="inputs-group-item">{t('common.donorStatus.header')}</div>
                  <div className="inputs-group-item">
                    <CustomSelect
                      data={ allDonorStatuses }
                      value={ selectedStatus }
                      onChange={ setSelectedStatus }
                      placeholder="Select"
                      additionalClassName="lg"
                    />
                  </div>
                  <div className="inputs-group-item d-flex flex-grow-1 justify-content-end">
                    <button 
                      className="btn-flat"
                      onClick={ addDonorStatusTag } 
                    >
                      +{t('configuration.settings.add')}
                    </button>
                  </div>
                </div>
                <div className='inputs-group form-tags'>
                  <Checkbox
                    label={t('configuration.settings.titer.allVariants')}
                    checked={ allBloodStatusesIsChecked }
                    onChangeValue={ setAllBloodStatusesIsChecked }
                    checkboxHandler={ handleAllDonorStatusesChange }
                  />
                </div>
                <div className="form-tags">
                  {
                    selectedDonorStatuses?.length ?
                      <Tags 
                        additionalClass="inline" 
                        data={ selectedDonorStatuses } 
                        onDeleteTag={ setSelectedDonorStatuses }
                      /> : 
                      <></>
                  }
                </div>
              </div>
              <div className="form-group">
                <div className="inputs-group">
                  <div className="inputs-group-item">{t('common.appUserStatus.header')}</div>
                  <div className="inputs-group-item">
                    <CustomSelect
                      data={ allAppUserStatuses }
                      value={ selectedAppUserStatus }
                      onChange={ setSelectedAppUserStatus }
                      placeholder="Select"
                      additionalClassName="lg"
                    />
                  </div>
                  <div className="inputs-group-item d-flex flex-grow-1 justify-content-end">
                    <button 
                      className="btn-flat"
                      onClick={ addAppUserStatusTag } 
                    >
                      +{t('configuration.settings.add')}
                    </button>
                  </div>
                </div>
                <div className='inputs-group form-tags'>
                  <Checkbox
                    label={t('configuration.settings.titer.allVariants')}
                    checked={ allAppUserStatusesIsChecked }
                    onChangeValue={ setAllAppUserStatusesIsChecked }
                    checkboxHandler={ handleAllAppUserStatusesChange }
                  />
                </div>
                <div className="form-tags">
                  {
                    selectedAppUserStatuses?.length ?
                      <Tags 
                        additionalClass="inline" 
                        data={ selectedAppUserStatuses } 
                        onDeleteTag={ setSelectedAppUserStatuses }
                      /> : 
                      <></>
                  }
                </div>
              </div> 

              <div className="form-group">
                <div className="inputs-group">
                  <div className="inputs-group-item">{t('common.smsStatus.header')}</div>
                  <div className="inputs-group-item">
                    <CustomSelect
                      data={ allSmsStatuses }
                      value={ selectedSmsStatus }
                      onChange={ setSelectedSmsStatus }
                      placeholder="Select"
                      additionalClassName="lg"
                    />
                  </div>
                  <div className="inputs-group-item d-flex flex-grow-1 justify-content-end">
                    <button 
                      className="btn-flat"
                      onClick={ addSmsStatusTag } 
                    >
                      +{t('configuration.settings.add')}
                    </button>
                  </div>
                </div>
                <div className='inputs-group form-tags'>
                  <Checkbox
                    label={t('configuration.settings.titer.allVariants')}
                    checked={ allSmsStatusesIsChecked }
                    onChangeValue={ setAllSmsStatusesIsChecked }
                    checkboxHandler={ handleAllSmsStatusesChange }
                  />
                </div>
                <div className="form-tags">
                  {
                    selectedSmsStatuses?.length ?
                      <Tags 
                        additionalClass="inline" 
                        data={ selectedSmsStatuses } 
                        onDeleteTag={ setSelectedSmsStatuses }
                      /> : 
                      <></>
                  }
                </div>
              </div> 

              <div className="form-group">
                <div className="inputs-group">
                  <div className="inputs-group-item">{t('common.quarantineDue')}</div>
                  <div className="inputs-group-item">
                    <Datepicker
                      date={datepickerQuarantineDate}
                      onDateChange={setDatepickerQuarantineDate}
                      calendarPosition='top' />
                  </div>
                  <div className="inputs-group-item d-flex flex-grow-1 justify-content-end">
                    <button 
                      className="btn-flat"
                      onClick={ addQuarantineDueDate } 
                    >
                      +{t('configuration.settings.add')}
                    </button>
                  </div>
                </div>
                <div className="form-tags">
                  {
                    quarantineDueDate?.length ?
                      <Tags
                        additionalClass="inline"
                        data={(quarantineDueDate)}
                        onDeleteTag={setQuarantineDueDate}
                      /> : 
                      <></>
                  }
                </div>
              </div> 

              <div className="form-group">
                <div className="inputs-group">
                  <div className="inputs-group-item">{t('common.nextAppointment')}</div>
                  <div className="inputs-group-item">
                    <Datepicker
                      date={datepickerNextAppointmentDate}
                      onDateChange={setDatepickerNextAppointmentDate}
                      calendarPosition='top' />
                  </div>
                  <div className="inputs-group-item d-flex flex-grow-1 justify-content-end">
                    <button 
                      className="btn-flat"
                      onClick={ addNextAppointmentDate } 
                    >
                      +{t('configuration.settings.add')}
                    </button>
                  </div>
                </div>
                <div className="form-tags">
                  {
                    nextAppointmentDate?.length ?
                      <Tags
                        additionalClass="inline"
                        data={(nextAppointmentDate)}
                        onDeleteTag={setNextAppointmentDate}
                      /> : 
                      <></>
                  }
                </div>
              </div> 

            </section>
            <section className="modal-content light">
            </section>
        </CustomScrollbar>

        <div className="modal-footer">
          <div className="inputs-group">
            <div className="inputs-group-item">
              <Button type="button" text={t('common.cancel')} onClick={ handleModalClose } />
            </div>
            <div className="inputs-group-item">
              <Button
                btnClassName="btn-primary"
                type="button"
                text={t('common.save')}
                onClick={ handleSave }
              />
            </div>
          </div>
        </div>
          
        </Modal>
      </>  
    );
}