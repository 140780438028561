import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

import CustomScrollbar from '../../CustomScrollbar';
import {
  NO_AVAILABLE_RESULTS,
  OPTION_HEIGHT,
  OPTIONS_AMOUNT,
} from './constants';

const ReactSelect = (props) => {
  const {
    data,
    placeholder,
    value,
    label,
    onMenuOpen,
    onMenuClose,
    additionalClassName,
    isSearchable,
    hasError,
    onChange,
    errorMessage: error,
    disabled,
    isDisabled
  } = props;

    
  const [errorMessage, setErrorMessage] = useState(error);
  const [isValid, setIsValid] = useState(!hasError);


  const controlClass = isValid ? 'form-control' : 'form-control has-error';

  const options = data.length ? data : NO_AVAILABLE_RESULTS;

  const searchableClass = isSearchable ? 'is-searchable' : '';

  const selectClassName = `select ${searchableClass}`;

  const fieldClassName = `form-field ${ additionalClassName }`;

  const handleCloseMenuOnScroll = (e) => {
    const target = e.target;
    const scrollableElement = target.parentElement;

    return !(scrollableElement && (scrollableElement.classList.contains('react-select__menu')));
  };

  useEffect(() => {
    setErrorMessage(error);
    setIsValid(!hasError);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    setIsValid(!hasError);
  }, [hasError]);

  useEffect(() => {
    setIsValid(true);
  }, [value]);  
 
  const CustomMenuList = (data) => {
    const [scrollEl, setScrollEl] = useState(null);

    useEffect(() => {
      if(scrollEl) {
        const offset = (value && value.id) 
        ? (value.id * scrollEl.firstChild.clientHeight - (scrollEl.clientHeight + scrollEl.firstChild.clientHeight) / 2)
        : 0;
        scrollEl.scrollTop = offset;
      }
    }, [scrollEl]);
  
    return (
      <CustomScrollbar 
        className="perfect-scrollbar react-select__menu-list"
        containerRef={ref => {          
          if (!scrollEl) setScrollEl(ref);
        }}
      >
          {data.children}
      </CustomScrollbar>
    )
  };

  return (
    <>
      <div className={controlClass}>
        {label && (
          <label htmlFor="" className="form-label">
            {label}
          </label>
        )}
        <div className={fieldClassName}>
          <Select
            className={selectClassName}
            selectProps={selectClassName}
            options={options}
            value={value}
            classNamePrefix="react-select"
            isDisabled={isDisabled}
            placeholder={placeholder || 'Select'}
            isOptionDisabled={disabled ? () => true : (option) => option.disabled}
            isSearchable={isSearchable}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            menuPlacement="auto"
            closeMenuOnScroll={handleCloseMenuOnScroll}
            components={{
              MenuList: CustomMenuList
            }}
            styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            onChange={onChange}
            {...props}
          />
        </div>
        <span className="form-error">{errorMessage}</span>
      </div>
    </>
  );
};

ReactSelect.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      disabled: PropTypes.bool,
      isFixed: PropTypes.bool,
    })
  ),
  isDisabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool
};

ReactSelect.defaultProps = {
  label: '',
  placeholder: 'Select value',
  data: [],
  isDisabled: false,
  isSearchable: false,
  hasError: false,
  errorMessage: '',
  disabled: false
};

export default ReactSelect;
