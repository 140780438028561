import { ApiError } from './helpers'
import firebase from 'firebase/compat/app';

const getToken = async () => {
  try {
    const token = await firebase.auth().currentUser.getIdToken(true);
    return token;
  } catch (error) {
    return false;
  }
};

const customFetch = (url, options, link = 'REACT_APP_API_URL') => {
  return getToken()
    .then((token) => {
      let authorization = {}
      if (token) {
        authorization = { Authorization: `Bearer ${token}` };
      }
      return fetch(`${process.env[link]}${url}`, {
        ...options,
        headers: { ...(options?.headers ? options?.headers : {}), ...authorization },
      });
    })
    .then((response) => Promise.all([response.ok, response.json(), response.status, response.url]))
    .then(([responseOk, body, status, url]) => {
      if (responseOk) {
        return body;
      } else {
        throw new ApiError(body.message, status, url);
      }
    })
    .catch((e) => {
      if (e?.name !== 'AbortError') {
        throw new ApiError(e.message, e.status, url);
      }
    });
};

export const getNewUsersRegistered = (url) => customFetch(url);

export const getUnEncryptedUserById = (userId, token) => {
  return customFetch(
    `/users/${userId}`,
    {
      method: 'GET',
      headers: {Authorization: `Bearer ${token}`}
    },
    'REACT_APP_API_URL2'
  );
}

export const signIn = (email, password) => {
  return customFetch('/login', {
    method: 'POST',
    body: JSON.stringify({
      email, password
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

export const getCustomToken = (token) => {
  return customFetch('/token', {
    method: 'POST',
    body: JSON.stringify({ token }),
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

export const getUnEncryptedUserByPnr = (userPnr, token) => {
  return customFetch(
    `/users?pnr=${userPnr}`,
    {
      method: 'GET',
      headers: {Authorization: `Bearer ${token}`}
    },
    'REACT_APP_API_URL2'
  );
}

export const editEncryptedUser = (userId, data, token) => {
  return customFetch(
  `/users/${userId}`,
  {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  },
  'REACT_APP_API_URL2',
);
}

export const getReservationsForDay = (url, options) => customFetch(url, options);

export const editReservation = (url, data, userId) => customFetch(url, {
  method: 'PUT',
  body: JSON.stringify({ 
    donorType: data.donorType,
    donorStatus: data.donorStatus,
    appointmentType: data.appointmentType,
    quarantine: data.quarantine,
    quarantineDesc: data.quarantineDesc,
    lastDonation: data.lastDonation,
    userId 
  }),
  headers: {
    'Content-Type': 'application/json',
  },
});

export const createReservation = (url, startDate, timeslotId, userId) => customFetch(url, {
  method: 'POST',
  body: JSON.stringify({startDate, timeslotId, userId}),
  headers: {
    'Content-Type': 'application/json',
  },
});

export const overwriteTimeslotAndBook = (url, startDate, timeslotId, userId) => customFetch(url, {
  method: 'PUT',
  body: JSON.stringify({startDate, timeslotId, userId}),
  headers: {
    'Content-Type': 'application/json',
  },
});

export const cancelReservation = (url, reservationId, userId, reservationTimestamp, bloodbankName) => customFetch(url, {
  method: 'POST',
  body: JSON.stringify({reservationId, userId, reservationTimestamp, bloodbankName, pushMsg: true}),
  headers: {
    'Content-Type': 'application/json',
  },
});

export const updateStatus = (url, status, userStatus) => customFetch(url, {
  method: 'PUT',
  body: JSON.stringify({status, userStatus}),
  headers: {
    'Content-Type': 'application/json',
  },
});

export const createTimeslot = (data, bloodbankId) => customFetch(`/timeslots/bloodbank/${bloodbankId}`, {
  method: 'POST',
  body: JSON.stringify(data),
  headers: {
    'Content-Type': 'application/json',
  },
});

export const editTimeslot = (data, bloodbankId) => customFetch(`/timeslots/bloodbank/${bloodbankId}/${data.timeslotId}`, {
  method: 'PUT',
  body: JSON.stringify(data),
  headers: {
    'Content-Type': 'application/json',
  },
});

export const deleteTimeslot = (data, bloodbankId) => customFetch(`/timeslots/bloodbank/${bloodbankId}`, {
  method: 'DELETE',
  body: JSON.stringify(data),
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getTimetable = (url, options) => customFetch(url,options);
export const get = (url, options) => customFetch(url, options);

export const getCalendar = (url, options) => customFetch(url, options);

export const resourceCreate = (url, data) => customFetch(url, {
  method: 'POST',
  body: JSON.stringify(data),
  headers: {
    'Content-Type': 'application/json',
  },
});

export const resourceEdit = (url, data) => customFetch(url, {
  method: 'PUT',
  body: JSON.stringify(data),
  headers: {
    'Content-Type': 'application/json',
  },
});

export const resourceDelete = (url) => customFetch(url, {
  method: 'DELETE',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const checkReservationsExists = (timeslotId, bloodbankId) =>
  customFetch(`/timeslots/bloodbank/${bloodbankId}/${timeslotId}/check`);

export const calcAppointmentsInTimeslot = (timeslotId, bloodbankId) =>
  customFetch(`/timeslots/bloodbank/${bloodbankId}/${timeslotId}/calc`);

export const addPhoneRequest = (url, data) => customFetch(url, {
  method: 'POST',
  body: JSON.stringify(data),
  headers: {
    'Content-Type': 'application/json',
  },
})

export const getFromUsers = (url, data) => customFetch(url, {
  method: 'POST',
  body: JSON.stringify(data),
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getUser = (url, data) => customFetch(url, {
  method: 'GET',
  body: JSON.stringify(data),
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getService = (url, data) => customFetch(url, {
  method: 'GET',
  body: JSON.stringify(data),
  headers: {
    'Content-Type': 'application/json',
  },
});

export const createPushMessage = (url, data) => customFetch(url, {
  method: 'POST',
  body: JSON.stringify(data),
  headers: {
    'Content-Type': 'application/json',
  },
});

export const createEmail = (url, data) => customFetch(url, {
  method: 'POST',
  body: JSON.stringify(data),
  headers: {
    'Content-Type': 'application/json',
  },
});

export const createErrorLog = (url, data) => customFetch(url, {
  method: 'POST',
  body: JSON.stringify(data),
  headers: {
    'Content-Type': 'application/json',
  },
});

export const createLoginAttemptLog = (url, data) => customFetch(url, {
  method: 'POST',
  body: JSON.stringify(data),
  headers: {
    'Content-Type': 'application/json',
  },
});

// https://us-central1-labcraft-playground.cloudfunctions.net
// http://localhost:5000/labcraft-playground/us-central1
// http://localhost:5000/labcraft-ekm/us-central1/api
// https://us-central1-labcraft-ekm.cloudfunctions.net