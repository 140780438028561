import * as dateFns from 'date-fns';
import {
  isAppIsoFormat,
  isoToTimestamp,
  parseToDateString,
  timestampToIso,
  timestampToWorkingTime,
} from '../../../services/helpers';
import { WEEKDAYS } from './constants';
import { DateTime } from "luxon";

export const changeTimestampByDTS = (timestamp, timeslotStart, reverse=true) => {
  const offsetDST = 3600000;

  if (!timeslotStart) {
    return timestamp;
  }

  const isTimeslotStartDST = DateTime.fromMillis(timeslotStart).isInDST;
  const isCurrentDST = DateTime.fromMillis(timestamp).isInDST;

  if(isTimeslotStartDST === true && isCurrentDST === false) {
    return reverse ? timestamp - offsetDST : timestamp + offsetDST;
  }
  if(isTimeslotStartDST === false && isCurrentDST === true) {
    return reverse ? timestamp + offsetDST : timestamp - offsetDST;
  }
  return timestamp;
}

export const isSelectedTab = (selectedTab, tab) => selectedTab === tab;

export const prepareDatesToMapping = (data) => {
  const dates = [...data];
  return dates.map((item, index) => {
    const isoDate = timestampToIso(item);
    return {
      id: String(index + 1),
      label: parseToDateString(item, "dd MMMM yyyy"),
      value: parseToDateString(item, "dd_MM_yy"),
      sentValue: isoDate,
    }
  });
}

export const prepareHoursToMapping = (data) => {
  const hours = [...data];
  return hours.map((item, index) => {
    const timeFrom = timestampToWorkingTime(isoToTimestamp(item.from));
    const timeTo = timestampToWorkingTime(isoToTimestamp(item.to));
    const checkedDays = WEEKDAYS.filter((el) => item.byweekday.includes(Number(el.value)));
    const labelDays = checkedDays.map((item) => item.label).join(', ');
    const valueDays = checkedDays.map((item) => item.label).join('_');
    const isoSentItem = {
      ...item,
      from: timestampToIso(item.from),
      to: timestampToIso(item.to),
    }

    return {
      id: String(index + 1),
      label: `From ${timeFrom.label} to ${timeTo.label} on ${labelDays}`,
      value: `${timeFrom.value}_${timeTo.value}_${valueDays}`,
      sentValue: isoSentItem
    }
  });
}

export const prepareHoursOnDateToMapping = (data) => {
  const hoursAndDates = [...data];
  return hoursAndDates.map((item, index) => {
    const timeFrom = timestampToWorkingTime(isoToTimestamp(item.from));
    const timeTo = timestampToWorkingTime(isoToTimestamp(item.to));
    const labelDate = parseToDateString(item.date, "dd MMMM yyyy");
    const valueDate = parseToDateString(item.date, "dd_MM_yy");
    const isoSentItem = {
      date: timestampToIso(item.date),
      from: timestampToIso(item.from),
      to: timestampToIso(item.to),
    }

    return {
      id: String(index + 1),
      label: `From ${timeFrom.label} to ${timeTo.label} on ${labelDate}`,
      value: `${timeFrom.value}_${timeTo.value}_${valueDate}`,
      sentValue: isoSentItem
    }
  });
}

export const prepareToSending = (data) => {
  const newData = [...data];
  return newData.map((item) => {   
    return item.sentValue
  });
}

export const makeTextRules = (exceptionDates, exceptionRules, exceptionHoursOnDates, timeslotStart) => {
  const dates = [...exceptionDates];
  const rules = [...exceptionRules];
  const hoursOnDates = [...exceptionHoursOnDates];
  
  return [
    ...dates.map((item) => parseToDateString(item,"dd MMMM yyyy")), 
    ...rules.map((item) => {
      const timeFrom = timestampToWorkingTime(isoToTimestamp(item.from));
      const timeTo = timestampToWorkingTime(isoToTimestamp(item.to));
      const checkedDays = WEEKDAYS.filter((el) => item.byweekday.includes(Number(el.value)));
      const labelDays = checkedDays.map((item) => item.label).join(', ');
      return `From ${timeFrom.label} to ${timeTo.label} on ${labelDays}`
    }),
    ...hoursOnDates.map((item) => {
      const timeFrom = timestampToWorkingTime(isoToTimestamp(item.from));
      const timeTo = timestampToWorkingTime(isoToTimestamp(item.to));
      const labelDate = parseToDateString(isoToTimestamp(item.date), "dd MMMM yyyy");
      return `From ${timeFrom.label} to ${timeTo.label} on ${labelDate}`
    })
  ]
}

export const prepareTimeslotForUpdating = (timeslot) => {
  const parsedTimeSlotObj = {
    appointmentTypeId: timeslot.appointmentType.appointmentTypeId,
    bloodTiter: timeslot.bloodTiter,
    donorType: timeslot.donorType,
    endDate: timeslot.endDate,
    exceptionDates: timeslot.initialExceptionDates,
    exceptionHoursOnDates: timeslot.initialExceptionHoursOnDates,
    exceptionRules: timeslot.initialExceptionRules,
    resourceId: timeslot.resourceId,
    ruleDescription: timeslot.ruleDescription,
    startDate: timeslot.startDate,
    timeslotId: timeslot.timeslotId,
  }
  return parsedTimeSlotObj;
}
export const prepareTimeslotForCreation = (timeslot, timeSlotForCopy) => {
  const parsedTimeSlotObj = {
    ...timeslot,
    resourceId: timeSlotForCopy?.resourceId,
    exceptionDates: [],
    exceptionHoursOnDates: [],
    exceptionRules: [],
  }
  return parsedTimeSlotObj;
}