import React, { useEffect } from 'react';

import Modal from '../../common/Modal';
import Button from '../../common/FormFields/Button';
import { useTranslation } from 'react-i18next';
import Loader from '../../common/Loader';
import { toast } from 'react-toastify'

const OverWriteModal = (props) => {
  const {
    isModalOpen, handleClose, onApply, isInProgress,isError
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    if(isError){
      toast.error('Something went wrong', {position: toast.POSITION.BOTTOM_RIGHT})
    }
  }, [isError])

  const handleModalClose = (e) => {
    if (isInProgress) {
      e.preventDefault()
      return;
    }
    handleClose?.()
  }
  return (
    <>
      <Modal isModalOpen={isModalOpen} handleModalClose={handleClose} {...props}>
        {isInProgress ? <Loader className="is-fixed_transparent"/> : (<> <div className="modal-header">
          <h2 className="modal-title">{t('configuration.overwriteModal.header')}</h2>
          <button className="modal-close" onClick={handleClose}>
            <span className="icon-close" />
          </button>
        </div>
          <div className="modal-body modal-content">
            <div className="modal-msg">{t('configuration.overwriteModal.body')}</div>
          </div>
          <div className="modal-footer light w-100">
            <div className="inputs-group">
              <div className="inputs-group-item">
                <Button btnClassName="m-10" type="button" text={t('common.cancel')} onClick={(e)=>handleModalClose(e)} />
              </div>
              <div className="inputs-group-item d">
                <Button btnClassName="btn-primary" type="button" text={t('configuration.overwriteModal.applyButton')} onClick={onApply} />
              </div>
            </div>
          </div></>)}
      </Modal>
    </>
  );
};

export default OverWriteModal;
