import { connect } from 'react-redux';
import { toggleBookAppointmentModal } from "../../../redux/timeslots/actions";
import { getUserByPnr } from "../../../redux/users/actions";
import { bookAppointment, overwriteTimeslot, toggleOverwriteModal } from "../../../redux/reservations/actions";
import BookAppointment from "./BookAppointment";

const mapStateToProps = (state) => ({
  selectedTimeslot: state.timeslotsReducer.selectedTimeslot,
  bookAppointmentModalIsOpen: state.timeslotsReducer.bookAppointmentModalIsOpen,
  userByPnr: state.usersReducer.userByPnr,
  bookedReservations: state.timeslotsReducer.bookedReservations,
  bloodBankId: state.authReducer.currentBloodbank,
  isOverwriting:state.reservationReducer.isTimeslotOverwriting,
  overwritingError: state.reservationReducer.overwritingError,
  isOverwriteModalOpened: state.reservationReducer.isOverwriteModalOpened,
  appointmentTypes: state.appointmentTypesReducer.appointmentTypes || [],
  recurrenceRule: state.timeslotsReducer.recurrenceRule,
});

const mapDispatchToProps = {
  toggleBookAppointmentModal,
  getUserByPnr,
  bookAppointment,
  overwriteTimeslot,
  toggleOverwriteModal
};

export default connect(mapStateToProps, mapDispatchToProps)(BookAppointment);
