import { db } from './store';
import * as dateFns from 'date-fns';

export const listenReservations = (dailyView, startDateTimestamp, bloodbankId, observer) => {
  const durationToAdd = dailyView ? {days: 1} : {months: 1};
  const lastDate = dateFns.add(startDateTimestamp, durationToAdd);

  return db.collection('bloodbanks').doc(bloodbankId)
  .collection('reservations')
  .where("startDate", ">=", startDateTimestamp)
  .where("startDate", "<", lastDate.getTime())
  .onSnapshot(observer)
}

export const listenReservationsForTimeslot = (timeslotId, bloodbankId, observer) => {
  return db.collection('bloodbanks').doc(bloodbankId)
    .collection('reservations')
    .where("timeslotId", "==", timeslotId)
    .onSnapshot(observer)
}

export const listenNewRegUsers = (currentBloodbank, observer) => {
  return db.collection('_users_encrypted')
  .where("status", "==", "new_registration")
  .where('donationSiteIds', 'array-contains', currentBloodbank)
  .onSnapshot(observer)
}

export const listenUsersWithStatus = (currentBloodbank, observer) => {
  return db.collection('_users_encrypted')
  .where("status", "in", ["new_registration", "new", "approved", "established"])
  .where('donationSiteIds', 'array-contains', currentBloodbank)
  .onSnapshot(observer)
}

export const listenAllUsers = (currentBloodbank, observer) => {
  return db.collection('_users_encrypted')
  .where("status", "in", ["new_registration", "new", "approved", "established"])
  .where('donationSiteIds', 'array-contains', currentBloodbank)
  .onSnapshot(observer)
}

export const listenSubscriptions = (observer) => {
  return db.collection('subscriptions')
  .where("delete", "==", true)
  .onSnapshot(observer)
}

export const listenAdminMessages = (observer) => {
  return db.collection('sendPushAdminInit')
  .onSnapshot(observer)
}

export const listenSystemMessages = (observer) => {
  return db.collection('sendPush')
  .onSnapshot(observer)
}

export const listenScheduledMessages = (observer) => {
  return db.collection('sendPushScheduled')
  .onSnapshot(observer)
}