import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MainRouter from './Router';
import Loader from './components/common/Loader';
import ServicePage from './components/Service';
import 'react-toastify/dist/ReactToastify.css';
import './i18n';
import './extendDate';
import { fetchServiceStatus } from './redux/maintenance/actions';

function App() {
    const dispatch = useDispatch();
    const { isActive, title, text, isLoading } = useSelector((state) => state.serviceStatusReducer);

    useEffect(() => {
        dispatch(fetchServiceStatus(true));

        const interval = setInterval(() => dispatch(fetchServiceStatus(false)), 60000);
        return () => clearInterval(interval);
    }, [dispatch]);

    if (isLoading) {
        return <Loader className="is-fixed" />;
    }

    if (isActive) {
        return <ServicePage title={title} text={text} />;
    }

    return <MainRouter />;
}

export default App;
