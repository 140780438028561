import { handleActions } from 'redux-actions';
import * as dateFns from 'date-fns';
import { 
  requestFail,
  reservationsForDay,
  reservationsForPrevDays,
  changeCurrentDate,
  confirmedForDay,
  didAttend,
  didNotAttend,
  getTimetableSuccess,
  getScheduleSuccess,
  resetScheduleSuccess,
  changeDay,
  changeMonth,
  getCalendarSuccess,
  filterCalendarSuccess,
  changeConfigurationDateSuccess,
  createResourceSuccess,
  newUsersRegistered,
  editDonorSuccess,
  editNotChecked,
  refreshConfiguration,
  setCurrentAppTypeFilter,
  setCurrentBloodTypeFilter,
  setCurrentDonorStatusFilter,
  setCurrentTiterFilter,
  fetchingConfiguration,
  fetchingDay,
  fetchingMonth,
  fetchingSidebar,
  setRefreshed,
  changeReservationSuccess,
  setNotRefreshed,
  setConfigurationRefreshed,
  deleteResourceSuccess,
  editResourceSuccess,
  setResourceMenuIsOpen,
  setSelectedResource,
  setIsResourceEmpty,
  toggleDeleteResourceModal,
  toggleEditResourceModal,
  resetDailySchedule,
  overwritingTimeslot,
  toggleOverwriteModal,
} from './actions';

const initialState = {
  dailyReservations: [],
  prevDaysReservations: [],
  dailySchedule: [],
  timetable: [],
  isError: false,
  error: null,
  currentDate: dateFns.startOfDay(new Date()),
  confirmed: [],
  currentMonth: dateFns.startOfMonth(new Date()).getTime() - new Date().getTimezoneOffset() * 60000,
  currentDay: dateFns.startOfDay(new Date()).getTime(),
  calendar: [],
  calendarIsFetched: false,
  configurationDate: dateFns.startOfDay(new Date()),
  needRefresh: false,
  needRefreshConfiguration: false,
  newUsers: [],
  appTypeFilter: 'All',
  bloodTypeFilter: 'all',
  donorStatusFilter: 'all',
  titerFilter: 'all',
  isSidebarFetching: false,
  isDayViewFetching: false,
  isMonthViewFetching: false,
  isConfigurationFetching: false,
  timetableIsFetched: false,
  dayIsFetched: false,
  reservationsIsFetched: false,
  isToastSuccessShown: false,
  isToastErrorShown: false,
  resourceMenuIsOpened: false,
  selectedResource: null,
  isResourceEmpty: null,
  resourceDeleteModalIsOpened: false,
  resourceEditModalIsOpened: false,
  isTimeslotOverwriting: false,
  overwritingError: null,
  isOverwriteModalOpened:false,
};

const reservationReducer = handleActions({
  [requestFail]: (state, action) => ({
    ...state,
    isError: true,
    error: action.payload,
    isToastErrorShown: true,
    isDayViewFetching: false,
    isSidebarFetching: false,
  }),
  [reservationsForDay]: (state, action) => ({
    ...state,
    isSidebarFetching: false,
    reservationsIsFetched: true,
    dailyReservations: action?.payload?.content,
    needRefresh: false,
    isError: false,
    error: null,
    isToastErrorShown: false,
  }),
  [reservationsForPrevDays]: (state, action) => ({
    ...state,
    isSidebarFetching: false,
    reservationsIsFetched: true,
    prevDaysReservations: action?.payload?.content,
    needRefresh: false,
    isError: false,
    error: null,
    isToastErrorShown: false,
  }),
  [overwritingTimeslot]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [toggleOverwriteModal]:(state)=>({
    ...state,
    isOverwriteModalOpened: !state.isOverwriteModalOpened,
  }),
  [changeCurrentDate]: (state, action) => ({
    ...state,
    currentDate: action.payload,
  }),
  [changeReservationSuccess]: (state) =>({
    ...state,
    isDayViewFetching: false,
    isSidebarFetching: false,
    needRefresh: true,
    isToastErrorShown: false,
    isToastSuccessShown: true
  }),
  [didNotAttend]: (state) =>({
    ...state,
    isDayViewFetching: false,
    isSidebarFetching: false,
    needRefresh: true,
    isToastErrorShown: false,
    isToastSuccessShown: true
  }),
  [didAttend]: (state) =>({
    ...state,
    isDayViewFetching: false,
    isSidebarFetching: false,
    needRefresh: true,
    isToastErrorShown: false,
    isToastSuccessShown: true
  }),
  [getTimetableSuccess]: (state, action) => ({
    ...state,
    isMonthViewFetching: false,
    timetableIsFetched: true,
    timetable: action?.payload?.content,
    isToastErrorShown: false,
  }),
  [getScheduleSuccess]: (state, action) => ({
    ...state,
    isDayViewFetching: false,
    dailySchedule: action.payload?.content,
    needRefresh: false,
    isToastErrorShown: false,
  }),
  [resetDailySchedule]:(state) => ({
    ...state,
    dailySchedule: [],
  }),
  [resetScheduleSuccess]: (state, action) => ({
    ...state,
    appTypeFilter: 'All',
    bloodTypeFilter: 'all',
    donorStatusFilter: 'all',
    titerFilter: 'all',
    isDayViewFetching: false,
    needRefresh: true,
    isToastErrorShown: false,
  }),
  [changeMonth]: (state, action) => ({
    ...state,
    currentMonth: action.payload,
  }),
  [changeDay]: (state, action) => ({
    ...state,
    currentDay: action.payload,
  }),
  [getCalendarSuccess]: (state, action) => ({
    ...state,
    isConfigurationFetching: false,
    calendar: action.payload?.content,
    calendarIsFetched: true,
    needRefreshConfiguration: false,
    isToastErrorShown: false,
  }),
  [filterCalendarSuccess]: (state, action) => ({
    ...state,
    calendar: action.payload?.content,
  }),
  [changeConfigurationDateSuccess]: (state, action) => ({
    ...state,
    isConfigurationFetching: false,
    configurationDate: action.payload,
  }),
  [createResourceSuccess]: (state, action) => ({
    ...state,
    isConfigurationFetching: false,
    calendar: [...state.calendar, action.payload.resource],
    dailySchedule: [...state.dailySchedule, action.payload.resource],
    isToastErrorShown: false,
    isToastSuccessShown: true
  }),
  [deleteResourceSuccess]: (state, action) => ({
    ...state,
    isConfigurationFetching: false,
    calendar: [...state.calendar.filter((item) => item.resourceId !== action.payload?.content)],
    dailySchedule: [...state.dailySchedule.filter((item) => item.resourceId !== action.payload?.content)],
    isToastErrorShown: false,
    isToastSuccessShown: true
  }),
  [editResourceSuccess]: (state, action) => ({
    ...state,
    isConfigurationFetching: false,
    // calendar: [...state.calendar, action.payload.resource],
    // dailySchedule: [...state.dailySchedule, action.payload.resource],
    isToastErrorShown: false,
    isToastSuccessShown: true
  }),
  [newUsersRegistered]: (state, action) => ({
    ...state,
    isSidebarFetching: false,
    newUsers: action.payload?.content,
    needRefresh: false,
    isError: false,
    error: null,
    isToastErrorShown: false,
  }),
  [editDonorSuccess]: (state) =>({
    ...state,
    isSidebarFetching: false,
    isDayViewFetching: false,
    needRefresh: true,
    isToastErrorShown: false,
    isToastSuccessShown: true
  }),
  [refreshConfiguration]: (state) => ({
    ...state,
    needRefreshConfiguration: true,
  }),
  [setConfigurationRefreshed]: (state) => ({
    ...state,
    needRefreshConfiguration: false,
  }),
  [setCurrentAppTypeFilter]: (state, action) => ({
    ...state,
    appTypeFilter: action.payload,
  }),
  [setCurrentBloodTypeFilter]: (state, action) => ({
    ...state,
    bloodTypeFilter: action.payload,
  }),
  [setCurrentDonorStatusFilter]: (state, action) => ({
    ...state,
    donorStatusFilter: action.payload,
  }),
  [setCurrentTiterFilter]: (state, action) => ({
    ...state,
    titerFilter: action.payload,
  }),
  [fetchingSidebar]: (state) => ({
    ...state,
    isSidebarFetching: true,
    isToastSuccessShown: false
  }),
  [fetchingConfiguration]: (state) => ({
    ...state,
    isConfigurationFetching: true,
    isToastSuccessShown: false
  }), 
  [fetchingDay]: (state) => ({
    ...state,
    isDayViewFetching: true,
    isToastSuccessShown: false
  }),
  [fetchingMonth]: (state) => ({
    ...state,
    isMonthViewFetching: true,
    isToastSuccessShown: false
  }),
  [setRefreshed]: (state) => ({
    ...state,
    needRefresh: false,
  }),
  [setNotRefreshed]: (state) => ({
    ...state,
    needRefresh: true,
  }),
  [setResourceMenuIsOpen]: (state) => ({
    ...state,
    resourceMenuIsOpened: true,
  }),
  [setSelectedResource]: (state, action) => ({
    ...state,
    selectedResource: action.payload,
  }),
  [setIsResourceEmpty]: (state, action) => ({
    ...state,
    isResourceEmpty: action.payload,
  }),
  [toggleDeleteResourceModal]: (state) => ({
    ...state,
    resourceDeleteModalIsOpened: !state.resourceDeleteModalIsOpened,
  }),
  [toggleEditResourceModal]: (state) => ({
    ...state,
    resourceEditModalIsOpened: !state.resourceEditModalIsOpened,
  }),
},
initialState);

export default reservationReducer;