import { connect } from 'react-redux';
import TimeslotSettings from "./TimeslotSettings";
import {
  deleteRecurrenceRule, 
  setTimeslotRequest, 
  toggleSettings, 
  deleteAllExceptions,
  getAppointmentsAmount
} from "../../../redux/timeslots/actions";
import {calendarGet, createResource} from "../../../redux/reservations/actions";

const mapStateToProps = (state) => ({
  settingsModalIsOpen: state.timeslotsReducer.settingsModalIsOpen,
  selectedTimeslot: state.timeslotsReducer.selectedTimeslot,
  selectedResourceId: state.timeslotsReducer.selectedResourceId,
  selectedTime: state.timeslotsReducer.selectedTime,
  errors: state.timeslotsReducer.errors,
  currentDate: state.reservationReducer.currentDate,
  calendar: state.reservationReducer.calendar,
  recurrenceRule: state.timeslotsReducer.recurrenceRule,
  ruleDescription: state.timeslotsReducer.ruleDescription,
  appointmentTypes: state.appointmentTypesReducer.appointmentTypes,
  currentBloodbank: state.authReducer.currentBloodbank,
  exceptionRules: state.timeslotsReducer.exceptionRules,
  exceptionDates: state.timeslotsReducer.exceptionDates,
  exceptionHoursOnDates: state.timeslotsReducer.exceptionHoursOnDates,
  appointmentsAmount: state.timeslotsReducer.appointmentsAmount,
  bookedReservations:state.timeslotsReducer.bookedReservations,
});

const mapDispatchToProps = {
  setTimeslotRequest,
  toggleSettings,
  createResource,
  deleteRecurrenceRule,
  calendarGet,
  deleteAllExceptions,
  getAppointmentsAmount
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeslotSettings);
