import React, { useEffect, useState } from 'react';
import Button from '../../common/FormFields/Button';
import Input from '../../common/FormFields/Input';
import Password from '../../common/FormFields/Password';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LoginForm = (props) => {
  const { location, clearAuthValidationErrors, login, loginErrors } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    clearAuthValidationErrors();
  }, [location]);

  const handleClick = (e) => {
    e.preventDefault();
    login({ email, password });
  };

  return (
    <form action="/" className="form auth-form">
      <h1 className="auth-title">{t('auth.login')}</h1>
      <div className="auth-body">
        <div className="form-group">
          <Input
            type="text"
            placeholder={t('auth.enterEmailOrUsername')}
            label={t('auth.username')}
            onChangeValue={(val) => {
              setEmail(val);
              clearAuthValidationErrors()
            }}
          />
        </div>
        <div className="form-group">
          <Password
            placeholder={t('auth.enterPassword')}
            label={t('auth.password')}
            onChangeValue={(val) => { setPassword(val); clearAuthValidationErrors() }}
          />
        </div>
      </div>
      <div className="auth-footer">
        <Button
          btnClassName="btn-primary"
          text={t('auth.login')}
          onClick={handleClick}
          disabled={!email || !password}
        />
      </div>
      {loginErrors && <div className="form-login-error">{t('auth.loginError')}</div>}
    </form>
  )
};

export default withRouter(LoginForm);
