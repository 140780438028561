import {
    FETCH_SERVICE_STATUS_FAILURE,
    FETCH_SERVICE_STATUS_SUCCESS,
    FETCH_SERVICE_STATUS_REQUEST
} from './actionTypes'
import { getService } from '../../services/fetch';
import { createAction } from 'redux-actions';

export const fetchServiceStatusRequest = createAction(FETCH_SERVICE_STATUS_REQUEST);
export const fetchServiceStatusSuccess = createAction(FETCH_SERVICE_STATUS_SUCCESS);
export const fetchServiceStatusFailure = createAction(FETCH_SERVICE_STATUS_FAILURE);

export const fetchServiceStatus = (isInitial = false) => (dispatch) => {
    if (isInitial) dispatch(fetchServiceStatusRequest());

    return getService("/settings/maintenance")
        .then((res) => {
            dispatch(fetchServiceStatusSuccess(res));
        })
        .catch((err) => {
            dispatch(fetchServiceStatusFailure(err));
        });
};