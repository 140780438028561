import { handleActions } from 'redux-actions';
import {
  loginSuccess,
  loginFailure,
  forgotPasswordRequest,
  forgotPasswordFailure,
  loginRequest,
  changePasswordRequest,
  changePasswordFailure,
  changePasswordSuccess,
  clearValidationErrors,
  getProfileData,
  setCurrentCode,
  setCurrentPhone,
  addResend, 
  setPhone,
  resetResendCount,
  twoFactorError,
  setCurrentBloodbank,
  setToAllBloodbanks,
  setPasswords,
  userLogoutRequest,
} from './actions';

const initialState = {
  idToken: null,
  expTimestamp: null,
  fetching: false,
  allBloodbanks: [],
  currentBloodbank: '',
  bloodbankName: '',
  loginErrors: false,
  forgotPasswordError: null,
  changePasswordErrors: {
    oldPasswordError: null,
    newPasswordError: null,
  },
  imageRef: null,
  roleName: null,
  currentCode: null,
  phone: null,
  currentPhone: null,
  userId: null,
  resendCount: 0,
  isTwoFactorError: false,
  passwords: {
    oldPassword: null,
    newPassword: null,
  }
};

const authReducer = handleActions({
  [loginRequest]: (state) => ({
    ...state,
    loginErrors: false,
  }),
  [loginSuccess]: (state, action) => ({
    ...state,
    resendCount: 0,
    idToken: action.payload.token,
    expTimestamp: action.payload.claims.exp * 1000,
    isTwoFactorError: false,
  }),
  [loginFailure]: (state, action) => ({
    ...state,
    idToken: null,
    expTimestamp: null,
    loginErrors: true,
  }),
  [forgotPasswordRequest]: (state) => ({
    ...state,
    forgotPasswordError: null,
  }),
  [forgotPasswordFailure]: (state, action) => ({
    ...state,
    forgotPasswordError: action.payload,
  }),
  [changePasswordRequest]: (state) => ({
    ...state,
    fetching: true,
    changePasswordErrors: { oldPassword: null, newPassword: null, },
  }),
  [changePasswordSuccess]: (state) => ({
    ...state,
    fetching: false,
  }),
  [changePasswordFailure]: (state, action) => ({
    ...state,
    fetching: false,
    changePasswordErrors: { ...state.changePasswordErrors, ...action.payload },
  }),
  [clearValidationErrors]: (state) => ({
    ...state,
    loginErrors: false,
    changePasswordErrors: { oldPassword: null, newPassword: null, },
    forgotPasswordError: null,
  }),
  [getProfileData]: (state, action) => ({
    ...state,
    imageRef: action.payload.profileStorageRef,
    roleName: action.payload.roleName,
    phone: action.payload.phone,
    userId: action.payload.uid
  }),
  [setCurrentCode]: (state, action) => ({
    ...state,
    currentCode: action.payload,
    isTwoFactorError: false,
  }),
  [setCurrentPhone]: (state, action) => ({
    ...state,
    currentPhone: action.payload,
    isTwoFactorError: false,
  }),
  [addResend]: (state) => ({
    ...state,
    resendCount: parseInt(state.resendCount + 1)
  }),
  [setPhone]: (state, action) => ({
    ...state,
    currentPhone: action.payload,
    isTwoFactorError: false,
  }),
  [resetResendCount]: (state) => ({
    ...state,
    resendCount: 0,
    isTwoFactorError: false,
  }),
  [twoFactorError]: (state) => ({
    ...state,
    isTwoFactorError: true
  }),
  [setCurrentBloodbank]: (state, action) => ({
    ...state,
    currentBloodbank: action.payload?.bloodbankId,
    bloodbankName: action.payload?.bloodbankName,
  }),
  [setToAllBloodbanks]: (state, action) => ({
    ...state,
    allBloodbanks: [...action.payload],
  }),
  [setPasswords]: (state, action) => ({
    ...state,
    passwords: action.payload,
  }),
  [userLogoutRequest]: () => ({
    ...initialState
  })
}, initialState);

export default authReducer;
