import React, { useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import routes from '../../constants/routes';
import Header from '../common/Header';
import Button from '../common/FormFields/Button';
import Search from '../common/FormFields/Search';
import Loader from '../common/Loader';
import Table from '../common/Table';
import Pagination from '../common/Pagination';
import CustomScrollbar from '../common/CustomScrollbar';
import PushMessageForm from './PushMessageFormContainer';
import FilterSettingsModal from './FilterSettingsModalContainer';
import { prepareRowData } from './helpers';
import {
    MESSAGES_SETUP,
    MESSAGES_SETUP_ADMIN,
} from '../../constants/pushMessages';
import {
    PUSH_MESSAGES_PAGINATION,
} from '../../constants/pagination';
import { useTranslation } from 'react-i18next';
import { 
    listenAdminMessages,
    listenSystemMessages,
    listenScheduledMessages,
} from "../../services/firestoreServices";
import i18n from '../../i18n';
import { scrollSetting } from '../Dashboard/constants';


export const PushMessages = (props) => {
    const {
        currentBloodbank,
        togglePushMessagesModal,
        toggleFilterSettingsModal,
        adminMessages,
        systemMessages,
        scheduledMessages,
        getAdminMessages,
        getSystemMessages,
        getScheduledMessages,
        selectedFilters,
        sendErrorLog,
        userRoleName
    } = props;
    const {type} = useParams();
    const { t } = useTranslation();

    const [allMsgs, setAllMsgs] = useState([]);
    const [choosenMsgs, setChoosenMsgs] = useState([]);
    const [indexes, setIndexes] = useState([0, 0]);
    const [currentMsg, setCurrentMsg] = useState(null);
    const [isSendable, setIsSendable] = useState(true);
    const [currentSetup, setCurrentSetup] = useState(type === 'admin' ? MESSAGES_SETUP_ADMIN : MESSAGES_SETUP);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [filtersAmount, setFiltersAmount] = useState(0);
    const [scroll, setScroll] = useState(scrollSetting);

    const getScrollData = (container, direction) => {
        let scrollDirection = 'scrollTop';
    
        if (direction === 'scrollX') {
          scrollDirection = 'scrollLeft';
        }
        setScroll((prevCount) => {
          const newCount = {...prevCount};
          newCount[scrollDirection] = container[scrollDirection];
          return newCount;
        });
      };

    useEffect(() => {
        if (selectedFilters) {
            setFiltersAmount(selectedFilters.donorTypes?.length + selectedFilters.bloodTiters?.length +
                selectedFilters.donorStatuses?.length + selectedFilters.appUserStatuses?.length + selectedFilters.smsStatuses?.length + selectedFilters.quarantineDue?.length + selectedFilters.nextAppointment?.length);
        }
    }, [selectedFilters, setFiltersAmount]);

    useEffect(() => {
        setIsTableLoading(true);
        setAllMsgs([]);
        if (type === 'admin') {
            getAdminMessages(currentBloodbank);
        }
        if (type === 'system') {
            getSystemMessages(currentBloodbank);
        }
        if (type === 'scheduled') {
            getScheduledMessages(currentBloodbank);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    useEffect(() => {
        if (type === 'admin' && adminMessages) {
            setCurrentSetup(MESSAGES_SETUP_ADMIN);
            setAllMsgs(adminMessages.map((msg) => ({
                initial: {...msg},
                prepared: prepareRowData(msg),
            })));
        }
        if (type === 'system' && systemMessages) {
            setCurrentSetup(MESSAGES_SETUP);
            setAllMsgs(systemMessages.map((msg) => ({
                initial: {...msg},
                prepared: prepareRowData(msg),
            })));
        }
        if (type === 'scheduled' && scheduledMessages) {
            setCurrentSetup(MESSAGES_SETUP);
            setAllMsgs(scheduledMessages.map((msg) => ({
                initial: {...msg},
                prepared: prepareRowData(msg),
            })));
        }
        setIsTableLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adminMessages, systemMessages, scheduledMessages, i18n.language]);

    useEffect(() => {
        setChoosenMsgs(allMsgs.slice(indexes[0], indexes[1]));
    }, [allMsgs, indexes]);

    useEffect(() => {
        try {
            if(currentBloodbank) {
                if (type === 'admin') {
                    return listenAdminMessages({
                        next: () => {
                            getAdminMessages(currentBloodbank);
                        }
                    });
                }
                if (type === 'system') {
                    return listenSystemMessages({
                        next: () => {
                            getSystemMessages(currentBloodbank);
                        }
                    });
                }
                if (type === 'scheduled') {
                    return listenScheduledMessages({
                        next: () => {
                            getScheduledMessages(currentBloodbank);
                        }
                    });
                }
            }
        } catch (err) {
            if (err) sendErrorLog(err.message, err.code);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentBloodbank]);

    const handleModalOpen = () => {
        setCurrentMsg(null);
        setIsSendable(true);
        togglePushMessagesModal();
    }

    const handleRowChoose = (msg) => {
        setCurrentMsg(msg);
        if (type === 'admin') {
            setIsSendable(true);
        } else {
            setIsSendable(false);
        }
        togglePushMessagesModal();
    }

    const handlePagination = (firstIndex, lastIndex) => {
        setIndexes([firstIndex, lastIndex]);
    };    

    return (
    <>
        <div className="page-container">
        <Header />
            <div className="page-content">
            <main className="main">
                <CustomScrollbar onScrollY={(container) => getScrollData(container)}>
                    <div className="container-fluid content">
                    <div className="filters">
                        <div className="filters-item">
                            <Button
                                text={`+ ${t('msgs.filter')} (${filtersAmount})`}
                                btnClassName="btn-secondary"
                                onClick={toggleFilterSettingsModal}
                            />
                        </div>
                        <div className="filters-item">
                            <Button
                                disabled={!(userRoleName === 'admin')}
                                text={`+ ${t('msgs.message')}`}
                                btnClassName="btn-primary"
                                onClick={handleModalOpen}
                            />
                        </div>
                        <div className="filters-item">
                            <Search
                                placeholder={t('msgs.search')}
                            />
                        </div>
                    </div>
                    <div className="sub-header">
                        <nav className="nav sub-nav">
                            <ul className="nav-list">
                                <li className="nav-item">
                                    <NavLink 
                                        to={ `${ routes.PUSH_MESSAGES_ROUTE }/admin` } 
                                        className="nav-link"
                                        activeClassName="is-active">{t('msgs.admin')}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink 
                                        to={ `${ routes.PUSH_MESSAGES_ROUTE }/system` } 
                                        className="nav-link" 
                                        activeClassName="is-active">{t('msgs.system_sent')}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink 
                                        to={ `${ routes.PUSH_MESSAGES_ROUTE }/scheduled` } 
                                        className="nav-link" 
                                        activeClassName="is-active">{t('msgs.system_scheduled')}
                                    </NavLink>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    {   isTableLoading ? 
                        <Loader /> :
                        <>
                        <div className='table-container'>
                            <Table
                                data={choosenMsgs}
                                setup={currentSetup}
                                handleRowChoose={handleRowChoose}
                                handleSortBtnPress={() => {}}
                                additionalClass={type === 'admin' ? "table-admin-msgs" : "table-msgs"}
                                currentScrollPosition={scroll}
                            />
                        </div>
                        <div className='pagination-container'>
                            <Pagination
                                pageSize={PUSH_MESSAGES_PAGINATION.pageSize}
                                pageSizes={PUSH_MESSAGES_PAGINATION.pageSizes}
                                allPagesSize={PUSH_MESSAGES_PAGINATION.allPagesSize}
                                startPage={PUSH_MESSAGES_PAGINATION.startPage}
                                totalItems={allMsgs.length}
                                handleChange={handlePagination}
                            />
                        </div>
                        </>
                    }
                    </div>
                </CustomScrollbar>
            </main>
            </div>
        </div>
        <PushMessageForm
            msg={currentMsg}
            isSendable={isSendable}
        />
        <FilterSettingsModal />
    </>    
    );
}