import React, { useEffect, useRef, useState } from 'react';
import CustomScrollbar from '../CustomScrollbar';
import Loader from '../Loader';
import { scrollSetting } from '../../Dashboard/constants';
import emptyIcon from '../../../assets/img/search.svg';
import { useTranslation } from 'react-i18next';

const Table = (props) => {
  const {
    data, 
    setup,
    handleRowChoose,
    handleSortBtnPress,
    additionalClass,
    isUserPending,
    searchText,
    activeRow,
    currentScrollPosition
  } = props;
  const { t } = useTranslation();
  const isMsgTable = additionalClass === "table-admin-msgs" || additionalClass === "table-msgs"
  const [scroll, setScroll] = useState(scrollSetting);
  const tableRef = useRef(null);
  const theadRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [topOffset, setTopOffset] = useState(0);

  useEffect(() => {
    if (!tableRef.current || !theadRef.current) return;

    const theadRect = theadRef.current.getBoundingClientRect();
    const tableRect = tableRef.current.getBoundingClientRect();

    const containerScrollTop = currentScrollPosition.scrollTop;

    if (tableRect.top <= 90) {
      setIsSticky(true);
      setTopOffset(containerScrollTop > 0 ? containerScrollTop - theadRect.height - `${isMsgTable ? 60 : 20}` : 0);
    } else {
      setIsSticky(false);
      setTopOffset(0);
    }
  }, [currentScrollPosition.scrollTop, isMsgTable]);

  const getScrollData = (container, direction) => {
    let scrollDirection = 'scrollTop';

    if (direction === 'scrollX') {
      scrollDirection = 'scrollLeft';
    }
    setScroll((prevCount) => {
      const newCount = {...prevCount};
      newCount[scrollDirection] = container[scrollDirection];
      return newCount;
    });
  };

  const tableRowClassName = `table-row ${ additionalClass }`;

  const RenderRow = (props) => {
    const {
      row,
      index,
    } = props;

    return Object.keys(row.prepared).map((cell, cellIndex) => {
      
      const isHighlighted = setup[cellIndex].isHighlighted;
      const cellClassName = isHighlighted ? 'table-cell bold' : 'table-cell';
      const isTextOwerflowHidden = setup[cellIndex].isTextOwerflowHidden;
      const cellTextClassName = isTextOwerflowHidden ? 'cell-text big-text' : 'cell-text';


      return (
        <td className={cellClassName} key={`cell_${index}_${cellIndex}`}>          
          <div className={cellTextClassName}>
            {row.prepared[cell]}
          </div>
        </td>
      )
    })
  };

  const renderRowData = () => {
    const rows = data;
    return rows.map((row, index) => {
      const rowClassName = `${ tableRowClassName } ${row.initial.id === activeRow ? 'active-row last-row' : ''}`;
      return (
        <tr 
          className={ rowClassName } 
          key={`row_${index}`}
          onClick={() => {
            handleRowChoose(row.initial);
          }}
        >
          <RenderRow index={index} row={row}/>
        </tr>
      )
    })
  };

  const emptyData = (
    <div className="empty">
      <img className="empty-img" src={emptyIcon}
        alt="Please try another search"/>
        { searchText ?
          <>
            <p className="empty-text">{` ${t('table.no_match')} '${searchText}' `}</p>
            <p className="empty-text">{t('table.try_another')}</p>
          </>
        : <p className="empty-text">{t('table.no_data')}</p>
        }
    </div>
  );

  const getRowsData = () => {
    return isUserPending
      ? <Loader />
      : (data.length
          ? renderRowData()
          : emptyData
        );
  };

  const renderTableHeader = () => {
    let header = setup;
    return header.map((key, index) => {
      return (
        <th className="table-cell" key={index}>
          <div 
            className="table-cell-content"
            onClick={() => handleSortBtnPress(key.title, key.isSortable)}
          >
            <span 
              className="table-cell-title"
            >
              {t(`table.${key.title}`)}
            </span>
            {key.isSortable && 
            <span 
              className="table-arrow"
            />
            }
          </div>
        </th>
      )
    })
  };

  return (
    <div className="table">
      <table className="table-content" ref={tableRef}>
        <thead className="table-header" style={{ left: `-${scroll.scrollLeft}px`, top: isSticky ? `${topOffset}px` : "0", }} ref={theadRef} >
          <tr className={tableRowClassName}>
            {renderTableHeader()}
          </tr>
        </thead>
        <tbody className="table-body">
          <CustomScrollbar
            className=""
            options={{
              suppressScrollX: false,
            }}
            onScrollX={(container) => getScrollData(container, 'scrollX')}
          >
            {getRowsData()}
          </CustomScrollbar>
        </tbody>
      </table>
    </div>
  );
};

export default Table;
