const enTranslation = {
  header: {
    day: 'Day',
    week: 'Week',
    month: 'Month',
    administrator: 'Administrator',
    available: 'Available',
    logout: 'Log out',
    changeBloodbank: 'Change bloodbank',
    changePassword: 'Change password',
    changePhone: 'Change phone',
    unknownBloodbank: 'Unknown bloodbank',

    tooltip: {
      configuration: 'Configuration',
      week: 'Week',
    }
  },

  auth: {
    username: 'Username',
    enterEmailOrUsername: 'Enter your email or username',
    password: 'Password',
    enterPassword: 'Enter your password',
    forgotPassword: 'Forgot password',
    login: 'Log in',
    enterCorrectUsername: 'Enter the correct username',
    enterCorrectPassword: 'Enter the correct password',
    enterEmailToRecover: 'Please enter email to recover your password',
    enterEmail: 'Enter your email',
    email: 'Email',
    emailNotExist: 'Email does not exist',
    recoverPassword: 'Recover password',
    createNewPassword: 'Create new password',
    changeBloodbank: 'Select other bloodbank',
    changePassword: 'Change password',
    currentPassword: 'Current password',
    enterCurrentPassword: 'Enter the current password',
    newPassword: 'New password',
    enterNewPassword: 'Enter a new password',
    incorrectPassword: 'The password is incorrect',
    invalidPassword: 'Must contain at least 8 characters, including upper, lowercase and digit',
    identicalPassword: 'Passwords are identical',
    confirm: 'Confirm',
    code: 'Code',
    enterCodeToProceed: 'Please enter a code which we sent via a text message to your phone number',
    enterCode: 'Enter code',
    enterYourCode: 'Enter your code',
    invalidCode: 'Invalid code',
    codeWasResent: 'The code was resent to your phone number, please check a text message',
    phoneWasChanged: 'Your phone number is successfully changed',
    changePhoneNumber: 'Change phone number',
    newPhoneNumber: 'New phone number',
    enterPhoneNumber: 'Enter a phone number',
    invalidPhoneNumber: 'Invalid phone number',
    resentCode: 'If you haven’t received the code, please click',
    here: 'here',
    enterYourCountry: 'Enter your country',
    loginError: 'Wrong username or password',
  },

  configuration: {
    overwriteModal: {
      header: 'Overwrite Appointment',
      body: 'Original appointment will be lost. Are you sure you want overwrite appointment with new values',
      applyButton: 'Yes'
    },
    resourceDeleteModal: {
      header: 'Delete resource',
      body1: 'You can not delete a resource with the existing timeslot on it!',
      body2: 'Are you sure you want to delete this resource?',
      delete: 'Delete',
    },
    resourceEditModal: {
      header: 'Edit resource',
      body: 'Choose new name for this resource (if empty the index will be used)',
      input: 'Resource name',
      edit: 'Edit',
    },
    deleteResource: 'Delete resource',
    editResource: 'Edit resource',
    template: 'Choose template',
    Plain: 'Plain',
    newResource: 'New resource',
    resourceLimit: 'Resources limit is reached',
    editTimeslot: 'Edit timeslot',
    deleteAppointment: 'Delete appointment',
    bookAppointment: 'Book appointment',
    cancelBooking: 'Cancel booking',
    deleteTimeslot: 'Delete timeslot',
    downtime: 'Downtime',
    settings: {
      header: 'Timeslot settings',
      appointmentDuration: 'Appointment duration',
      allBloodTypes: 'All blood types',
      selectBloodType: 'Please select blood type',
      from: 'From',
      to: 'To',
      startDate: 'Start date',
      startTime: 'Start time',
      endTime: 'End time',
      shouldBeBeforeEnd: 'Should occur before the End time',
      shouldBeAfterStart: 'Should occur after the Start time',
      shouldBeGraterThanDuration: 'Should not be less than the appointment duration',
      shouldBeLessThanTheEndTime: 'Start time should be less than end time',
      shouldNotBeLessThanDuration: 'Time range should not be less than the appointment duration',
      allDay: 'All day',
      recurring: 'Recurring',
      exceptions: 'Exceptions',
      add: 'Add',
      discard: 'Discard',
      edit: 'Edit',
      remove: 'Remove',
      change: 'Change',
      titer: {
        allVariants: 'All variants',
        low: 'Low',
        high: 'High',
        unknown: 'Unknown',
        title: 'Titer (anti -A og -B)',
      },
      discardModal: {
        header: 'Discard changes?',
        body: 'All changes will be lost. Are you sure you want to discard unsaved changes?',
      },
      conflictModal: {
        header: 'Timeslots conflict',
        body: 'The new timeslot conflicts with the existing timeslot on the resource {{resourceIndex}}. You can change settings or proceed with creating of the timeslot on any available resource.',
      },
      noResourcesModal: {
        header: 'No available resources',
        body: 'There are no available resources. Please change the timeslot settings.',
      },
      cancelAppointmentsModal: {
        header: 'Cancel appointments',
        body: 'Please cancel all upcoming appointments before the changes to the timeslot can be done.',
      },
      deleteTimeslotModal: {
        header: 'Delete timeslot',
        body1: 'All upcoming reservations for the timeslot will be canceled. Are you sure you want to delete the timeslot?',
        body2: 'Are you sure you want to delete the timeslot?',
      },
      deleteAppointmentModal: {
        header: 'Delete appointment',
        body1: 'Are you sure you want to delete the appointment?',
        body2: 'You can not delete the last appointment!',
      },
      bookAppointmentModal: {
        header: 'Book appointment',
        id: 'ID-number',
        searchPlaceholder: 'Type donor id',
        noMatch: 'No match for',
        selectAppointmentTime: 'Select appointment time',
        nextAppointment: 'This user already has an appointment',
        statusNotMatch: 'User status does not match appointment',
        titerNotMatch: 'User titer does not match appointment',
        donorTypeNotMatch: 'Donor type does not match appointment',
        inQuarantine: 'User in quarantine',
      },
      cancelBookingModal: {
        header: 'Cancel booking?',
        body: 'Reserved booking will be lost. Are you sure you want to continue?',
        selectAppointmentTime: 'Select appointment time',
      },
      counter: {
        pending: 'Pending',
        reserved: 'Reserved',
        archived: 'Archived'
      },
    },
    recurrence: {
      header: 'Recurrence settings',
      daily: 'Daily',
      occur: 'Occur',
      everyDay: 'Every weekday',
      every: 'Every',
      days: 'days',
      weekly: 'Weekly',
      weeks: 'week(s)',
      on: 'On',
      monthly: 'Monthly',
      months: 'month(s)',
      onDay: 'On day',
      onThe: 'On the',
      First: 'First',
      Second: 'Second',
      Third: 'Third',
      Fourth: 'Fourth',
      Last: 'Last',
      ends: 'Ends',
      by: 'By',
      after: 'After',
      occurrences: 'occurrences',
      noEndDate: 'No end date',
    },
    exceptions: {
      header: 'Exception settings',
      excludeDate: 'Exclude date',
      addException: 'Add exception',
      dates: 'Dates',
      clearAll: 'Clear all',
      excludeHours: 'Exclude hours',
      from: 'From',
      to: 'To',
      onThe: 'On the',
      nonworkingHours: 'Hours',
      datesAndHours: 'Dates and hours',
      excludeHoursOnDate: 'Exclude hours on date',
    },
  },

  dashboard: {
    tasks: 'TASKS',
    findDonor: 'Find donor',
    search: 'Search',
    available: 'Available',
    reserved: 'Reserved',
    signups: 'Signups',
    attendance: 'Attendance',
  },

  donorDetails: {
    header: 'Donor details',
    idNumber: 'ID-number',
    lastDraw: 'Last appointment',
    noLastDraw: 'No last appointment',
    quarantine: 'Quarantine',
    quarantineExpires: 'Quarantine expires on',
    noQuarantineDate: 'No quarantine date',
    description: 'Description',
    nextAppointment: 'Next Appointment',
    donorContacts: 'Donor Contact Details',
    address: 'Address',
    postalCode: 'Postal code',
    city: 'City',
    email: 'Email',
    phone: 'Phone number',
    becs: 'Entry in BECS',
    appuser: 'APP-user',
    sms: 'SMS',
    donationSite : 'Donation site'
  },

  showUp: {
    header: 'Show-up',
    yes: 'Yes',
    no: 'No',
  },

  common: {
    quarantineDue:'Quarantine due',
    nextAppointment: 'Next Appointment',
    confirm: 'Confirm',
    book: 'Book',
    cancel: 'Cancel',
    save: 'Save',
    delete: 'Delete',
    proceed: 'Proceed',
    ok: 'Ok',
    all: 'All',
    bloodType: 'Blood type',
    donorType: {
      header: 'Donor type',
      full_blood: 'Full blood',
      apheresis: 'Apheresis',
    },
    titer: {
      low: 'Low',
      high: 'High',
      unknown: 'Unknown',
      title: 'Titer (anti -A og -B)',
    },
    appointmentType: {
      header: 'Appointment type',
      'New registration': 'New registration',
      'First time draw': 'First time draw',
      'N\'th time draw': 'N\'th time draw',
      'Erythrocytapheresis': 'Erythrocytapheresis',
      'Plateletpheresis': 'Plateletpheresis',
      'Plasmapheresis': 'Plasmapheresis',
    },
    appUserStatus: {
      header: 'APP-user',
      yes: 'Yes',
      no: 'No',
    },
    smsStatus: {
      header: 'SMS',
      true: 'Yes',
      false: 'No',
    },
    donorStatus: {
      header: 'Donor status',
      new: 'New',
      approved: 'Approved',
      established: 'Established',
    },
    month: {
      January: 'January',
      February: 'February',
      March: 'March',
      April: 'April',
      May: 'May',
      June: 'June',
      July: 'July',
      August: 'August',
      September: 'September',
      October: 'October',
      November: 'November',
      December: 'December',
      Jan: 'Jan',
      Feb: 'Feb',
      Mar: 'Mar',
      Apr: 'Apr',
      Jun: 'Jun',
      Jul: 'Jul',
      Aug: 'Aug',
      Sep: 'Sep',
      Oct: 'Oct',
      Nov: 'Nov',
      Dec: 'Dec',
    },
    day: {
      Sunday: 'Sunday',
      Monday: 'Monday',
      Tuesday: 'Tuesday',
      Wednesday: 'Wednesday',
      Thursday: 'Thursday',
      Friday: 'Friday',
      Saturday: 'Saturday',
      Sun: 'Sun',
      Mon: 'Mon',
      Tue: 'Tue',
      Wed: 'Wed',
      Thu: 'Thu',
      Fri: 'Fri',
      Sat: 'Sat',
      Su: 'Su',
      Mo: 'Mo',
      Tu: 'Tu',
      We: 'We',
      Th: 'Th',
      Fr: 'Fr',
      Sa: 'Sa',
    }
  },

  pagination: {
    previous: 'Previous',
    next: 'Next',
    rows_amount: 'Rows amount',
    of: 'of',
    all: 'all',
  },

  table: {
    no_data: 'There is no data',
    no_match: 'There is no match for',
    try_another: 'Please try another search',
    name: 'Name',
    ID_number: 'ID number',
    last_draw: 'Last appointment',
    blood_type: 'Blood type',
    titer: 'Titer',
    donor_status: 'Donor status',
    donor_type: 'Donor type',
    quarantine_due_date: 'Quarantine due date',
    app_user: 'APP-user',
    sms: 'SMS',
    next_appointment: 'Next appointment',
    
    id: 'ID',
    date: 'Date / Time',
    title: 'Title',
    message: 'Message',
    signature: 'Signature',
    candidate: 'Candidate',

    message_id: 'Message ID',
    receiver_id: 'Receiver ID',
    category: 'Category',
  },

  msgs: {
    index: 'Index',
    id: 'ID',
    date: 'Date / Time',
    title: 'Title',
    message: 'Message',
    authorized_user: 'Authorized user',
    messages_editor: 'Messages editor',
    send: 'Send',
    candidates_amount: 'Candidates amount',
    search: 'Search',
    admin: 'Admin',
    system_sent: 'Auto',
    system_scheduled: 'Scheduled',
    filter_settings: 'Filter settings',
    filter: 'Filter',
    message_id: 'Message ID',
    receiver_id: 'Receiver ID',

    tooltip: {
      bloodbank: 'Bloodbank',
      blood_type: 'Blood type',
      titer: 'Titer',
      donor_status: 'Donor status',
      app_user: 'APP-user',
      sms: 'SMS',
    }
  }
}

export default enTranslation;