import { createAction } from 'redux-actions';
import {
  SET_USERS,
  GET_USER_BY_PNR,
  GET_DONOR_DETAILS,
  GET_DONOR_SETTINGS,
  REQUEST_FAIL,
  REQUEST_PENDING,
  DELETE_DONOR_DETAILS,
  DELETE_DONOR_SETTINGS,
  TOGGLE_DONOR_DETAILS_MODAL,
  TOGGLE_DONOR_SETTINGS_MODAL,
  SET_LAST_CHOOSEN_DONOR,
} from './actionTypes';
import {
  getFromUsers,
  getUnEncryptedUserByPnr,
  getUnEncryptedUserById,
  getUser,
} from '../../services/fetch';
import { getValidIdToken, getIdToken } from "../auth/actions";
import { sendErrorLog } from "../logging/actions";

export const setUsers = createAction(SET_USERS);
export const userByPnr = createAction(GET_USER_BY_PNR);
export const getDonorDetails = createAction(GET_DONOR_DETAILS);
export const getDonorSettings = createAction(GET_DONOR_SETTINGS);
export const requestFail = createAction(REQUEST_FAIL);
export const requestPending = createAction(REQUEST_PENDING);
export const deleteDonorDetails = createAction(DELETE_DONOR_DETAILS);
export const deleteDonorSettings = createAction(DELETE_DONOR_SETTINGS);
export const toggleDonorDetailsModal = createAction(TOGGLE_DONOR_DETAILS_MODAL);
export const toggleDonorSettingsModal = createAction(TOGGLE_DONOR_SETTINGS_MODAL);
export const setLastChoosenDonor = createAction(SET_LAST_CHOOSEN_DONOR);

export const getFilteredUsers = (data) => (dispatch) => {
  getFromUsers('/users/selected', data)
  .then((res) => { 
    return dispatch(setUsers(res));
    }
  )
  .catch((err) => {
    return dispatch(requestFail(err));
  });
}

export const getUserByPnr = (userPnr) => async (dispatch) => {
  dispatch(requestPending());
  try {
    try {
      const token = await dispatch(getValidIdToken());
      const res = await getUnEncryptedUserByPnr(userPnr, token);
      dispatch(userByPnr(res));
    } catch (err) {
      if (/Invalid token provided.*/i.test(err.message)) {
        const token = await dispatch(getIdToken());
        const res = await getUnEncryptedUserByPnr(userPnr, token);
        dispatch(userByPnr(res));
      } else if (/no user found with pnr.*/i.test(err.message)) {
      } else {
        throw err;
      }
    }
  } catch (err) {
    dispatch(sendErrorLog(err.message, err.status, 'ekm', err.url));
    dispatch(requestFail(err));
  }
}

export const getDataForDonorDetails = (userId, details) => async (dispatch) => {
  dispatch(requestPending());
  try {
    try {
      const token = await dispatch(getValidIdToken());
      const res = await getUnEncryptedUserById(userId, token);
      const { donationSite } = await getUser(`/users/${userId}`);
      dispatch(getDonorDetails({ res: { data: { ...res.data, donationSite } }, details }));
    } catch (err) {
      if (/Invalid token provided.*/i.test(err.message)) {
        const token = await dispatch(getIdToken());
        const res = await getUnEncryptedUserById(userId, token);
        const { donationSite } = await getUser(`/users/${userId}`)
        dispatch(getDonorDetails({ res: { data: { ...res.data, donationSite } }, details }));
      } else {
        throw err;
      }
    }
  } catch (err) {
    dispatch(sendErrorLog(err.message, err.status, 'ekm', err.url));
    dispatch(requestFail(err));
  }
}

export const getDataForDonorSettings = (userId, reservation) => async (dispatch) => {
  dispatch(requestPending());
  try {
    try {
      const token = await dispatch(getValidIdToken());
      const res = await getUnEncryptedUserById(userId, token);
      dispatch(getDonorSettings({res, reservation}));
    } catch (err) {
      if (/Invalid token provided.*/i.test(err.message)) {
        const token = await dispatch(getIdToken());
        const res = await getUnEncryptedUserById(userId, token);
        dispatch(getDonorSettings({res, reservation}));
      } else {
        throw err;
      }
    }
  } catch (err) {
    dispatch(sendErrorLog(err.message, err.status, 'ekm', err.url));
    dispatch(requestFail(err));
  }
}