import { handleActions } from 'redux-actions';
import { fetchServiceStatusFailure, fetchServiceStatusRequest, fetchServiceStatusSuccess } from './actions'

const initialState = {
    isActive: false,
    title: '',
    text: '',
    isLoading: true,
};

const serviceStatusReducer = handleActions({
    [fetchServiceStatusRequest]: (state) => ({ ...state, isLoading: true }),

    [fetchServiceStatusSuccess]: (state, action) => ({
        ...state,
        isActive: action.payload.content.active,
        title: action.payload.content.title,
        text: action.payload.content.text,
        isLoading: false,
    }),

    [fetchServiceStatusFailure]: (state) => ({ ...state, isLoading: false, isActive: false }),

}, initialState)


export default serviceStatusReducer;