export const GET_RESERVATIONS_FOR_DAY = 'GET_RESERVATIONS_FOR_DAY';
export const GET_RESERVATIONS_FOR_PREV_DAYS = 'GET_RESERVATIONS_FOR_PREV_DAYS';
export const REQUEST_FAIL = 'REQUEST_FAIL';
export const CHANGE_CHOSEN_DATE = 'CHANGE_CHOSEN_DATE';
export const UPDATE_STATUS_CONFIRMED = 'UPDATE_STATUS_CONFIRMED';
export const UPDATE_STATUS_DENIED = 'UPDATE_STATUS_DENIED';
export const GET_CONFIRMED_RESERVATIONS = 'GET_CONFIRMED_RESERVATIONS';
export const MARK_DID_ATTEND = 'MARK_DID_ATTEND';
export const MARK_DID_NOT_ATTEND = 'MARK_DID_NOT_ATTEND';
export const GET_TIMETABLE_SUCCESS = 'GET_TIMETABLE_SUCCESS';
export const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS';
export const RESET_SCHEDULE_SUCCESS = 'RESET_SCHEDULE_SUCCESS';
export const CHANGE_CURRENT_DAY = 'CHANGE_CURRENT_DAY';
export const CHANGE_CURRENT_MONTH = 'CHANGE_CURRENT_MONTH';
export const GET_CALENDAR_SUCCESS = 'GET_CALENDAR_SUCCESS';
export const FILTER_CALENDAR_SUCCESS = 'FILTER_CALENDAR_SUCCESS';
export const CHANGE_CONFIGURATION_DATE = 'CHANGE_CONFIGURATION_DATE';
export const CREATE_RESOURCE_SUCCESS = 'CREATE_RESOURCE_SUCCESS';
export const DELETE_RESOURCE_SUCCESS = 'DELETE_RESOURCE_SUCCESS';
export const EDIT_RESOURCE_SUCCESS = 'EDIT_RESOURCE_SUCCESS';
export const EDIT_NOT_CHECKED = 'EDIT_NOT_CHECKED';
export const GET_NEW_USERS_REGISTERED = 'GET_NEW_USERS_REGISTERED';
export const UPDATE_DONOR = 'UPDATE_DONOR';
export const REFRESH_CONFIGURATION = 'REFRESH_CONFIGURATION';
export const SET_APP_TYPE_FILTER = 'SET_APP_TYPE_FILTER';
export const SET_BLOOD_TYPE_FILTER = 'SET_BLOOD_TYPE_FILTER';
export const SET_DONOR_STATUS_FILTER = 'SET_DONOR_STATUS_FILTER';
export const SET_TITER_FILTER = 'SET_TITER_FILTER';
export const FETCHING_SIDEBAR = 'FETCHING_SIDEBAR';
export const FETCHING_DAY = 'FETCHING_DAY';
export const FETCHING_MONTH = 'FETCHING_MONTH';
export const FETCHING_CONFIGURATION = 'FETCHING_CONFIGURATION';
export const SET_REFRESHED = 'SET_REFRESHED';
export const CHANGE_RESERVATION_SUCCESS = 'CHANGE_RESERVATION_SUCCESS';
export const SET_NOT_REFRESHED = 'SET_NOT_REFRESHED';
export const SET_CONFIGURATION_REFRESHED = 'SET_CONFIGURATION_REFRESHED';
export const TOGGLE_RESOURCE_MENU = 'TOGGLE_RESOURCE_MENU';
export const SET_RESOURCE = 'SET_RESOURCE';
export const SET_IS_RESOURCE_EMPTY = 'SET_IS_RESOURCE_EMPTY';
export const TOGGLE_DELETE_RESOURCE_MODAL = 'TOGGLE_DELETE_RESOURCE_MODAL';
export const TOGGLE_EDIT_RESOURCE_MODAL = 'TOGGLE_EDIT_RESOURCE_MODAL';
export const RESET_DAILY_SCHEDULE = 'RESET_DAILY_SCHEDULE';
export const OVERWRITE_TIMESLOT = 'OVERWRITE_TIMESLOT';
export const TOGGLE_OVERWRITE_MODAL = 'TOGGLE_OVERWRITE_MODAL';