export const scrollSetting = {
  scrollX: 0,
  scrollY: 0,
  scrollTop: 0,
  scrollLeft: 0,
};

export const DONOR_TYPE = [
  { value: 'all', label: 'All' },
  { value: 'o -', label: 'O-' },
  { value: 'o +', label: 'O+' },
  { value: 'a -', label: 'A-' },
  { value: 'a +', label: 'A+' },
  { value: 'b -', label: 'B-' },
  { value: 'b +', label: 'B+' },
  { value: 'ab -', label: 'AB-' },
  { value: 'ab +', label: 'AB+' },
];

export const DONOR_STATUS = [
  { value: 'all', label: 'all' },
  { value: 'new', label: 'new' },
  { value: 'approved', label: 'approved' },
  { value: 'established', label: 'established' },
];

export const BLOOD_TITER = [
  { value: 'all', label: 'all' },
  { value: 'low', label: 'low' },
  { value: 'high', label: 'high' },
  { value: 'unknown', label: 'unknown' },
];