const authCodes = {
  INVALID_EMAIL: 'auth/invalid-email',
  USER_NOT_FOUND: 'auth/user-not-found',
  WRONG_PASSWORD: 'auth/wrong-password',
  WEAK_PASSWORD: 'auth/weak-password',
};


export const loginFailureReason = {
  TOO_MANY_REQUESTS: 'Too many requests',
  WRONG_CREDENTIALS: "Wrong email or password",
  TWO_FACTOR_ERROR: "Two factor error",
  TWO_FACTOR_WRONG_CODE:"Two factor wrong code",
  NOT_FOUND: "User not found"
}

export default authCodes;
