import React, { useState, useEffect } from 'react';
import Modal from '../../common/Modal';
import Button from '../../common/FormFields/Button';
import Search from '../../common/FormFields/Search';
import CustomSelect from '../../common/FormFields/Select';
import * as dateFns from 'date-fns';
import { useTranslation } from 'react-i18next';
import OverWriteModal from './OverWriteModal';
import { prepareTimeslotForUpdating, prepareTimeslotForCreation } from './helpers';
import { extractPartToTimestamp, timestampToIso } from '../../../services/helpers';

const BookAppointment = (props) => {
  const {
    selectedTimeslot,
    bloodBankId,
    bookAppointmentModalIsOpen,
    toggleBookAppointmentModal,
    userByPnr,
    isOverwriting,
    overwritingError,
    getUserByPnr,
    bookAppointment,
    overwriteTimeslot,
    isOverwriteModalOpened,
    toggleOverwriteModal,
    appointmentTypes,
    recurrenceRule
  } = props;
  const { t } = useTranslation();
  const [userPnr, setUserPnr] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const [currentAppointments, setCurrentAppointments] = useState([]);
  const [chosenAppointment, setChosenAppointment] = useState(null);
  const [isTiterMatch, setIsTiterMatch] = useState(false);
  const [isDonorTypeMatch, setIsDonorTypeMatch] = useState(false);
  const [isInQuarantine, setIsInQuarantine] = useState(false);


  useEffect(() => {
    if (userPnr) {
      getUserByPnr(userPnr);
    }
  }, [getUserByPnr, userPnr]);

  useEffect(() => {
    setCurrentUser(userByPnr ? userByPnr : null);
  }, [userByPnr]);

  useEffect(() => {
    if (currentUser) {
      setIsTiterMatch(selectedTimeslot.bloodTiter && currentUser?.status !== 'new' && !selectedTimeslot.bloodTiter.find((item) => item === currentUser?.bloodTiter));
      setIsDonorTypeMatch(currentUser?.status !== 'new' && !selectedTimeslot.donorType.find((item) => item?.rhesus === currentUser?.donorType?.rhesus
        && item?.bloodType === currentUser?.donorType?.bloodType))
      setIsInQuarantine((currentUser && currentUser.quarantine && chosenAppointment) ? currentUser.quarantine > Number(chosenAppointment.value) : false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);


  useEffect(() => {
    setIsInQuarantine((currentUser && currentUser.quarantine && chosenAppointment) ? currentUser.quarantine > Number(chosenAppointment.value) : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenAppointment]);


  useEffect(() => {
    setCurrentAppointments(selectedTimeslot?.appointments
      .filter((item) => item.status === 'pending')
      .map((item) => {
        return {
          label: `${dateFns.format(new Date(item.startDate), 'HH:mm')} - ${dateFns.format(new Date(item.endDate), 'HH:mm')}`,
          value: String(item.startDate),
        }
      }) || []);
  }, [selectedTimeslot]);

  useEffect(() => {
    if (currentAppointments.length === 1) {
      setChosenAppointment(currentAppointments[0]);
    }
  }, [currentAppointments]);

  const handleSubmit = () => {
    bookAppointment(
      chosenAppointment.value,
      selectedTimeslot.timeslotId,
      currentUser.id,
      bloodBankId,
    );
    toggleBookAppointmentModal();
  };

  const handleModalClose = () => {
    toggleBookAppointmentModal();
  };

  useEffect(() => {
    setUserPnr('');
    setCurrentUser(null);
    setIsTiterMatch(false);
    setIsDonorTypeMatch(false);
    setIsInQuarantine(false);
    if (!bookAppointmentModalIsOpen) {
      setChosenAppointment(null);
    }
  }, [bookAppointmentModalIsOpen]);

  const formatText = (text) => {
    const firstPart = text.slice(0, 6);
    const secondPart = text.slice(6, 11);
    return `${firstPart}\u00A0${secondPart}`;
  };

  const handleCopy = (e) => {
    e.clipboardData.setData('text/plain', userPnr);
    e.preventDefault();
  };

  const handleOverwrite = () => {

    const timeSlotForOwerride = prepareTimeslotForUpdating(selectedTimeslot);

    let newAppointmentType;
    let newBloodTiter;
    let newDonorType;

    if (currentUser?.type === 'full_blood') {
      if (currentUser?.status === 'new') {
        newAppointmentType = appointmentTypes.find(el => el.donorStatus === "new")?.appointmentTypeId
        newBloodTiter = []
        newDonorType = []
      }
      else if (currentUser?.status !== 'new') {
        if (currentUser?.status === 'established') {
          newAppointmentType = appointmentTypes.find(el => el.donorStatus === "established" && el.name === `N'th time draw`)?.appointmentTypeId
        }
        else if (currentUser?.status === 'approved') {
          newAppointmentType = appointmentTypes.find(el => el.donorStatus = "approved" && el.name === `First time draw`)?.appointmentTypeId
        }
        newBloodTiter = [currentUser?.bloodTiter]
        newDonorType = [currentUser?.donorType]
      }
    }
    else {
      newAppointmentType = appointmentTypes.find(el => el.donorStatus === currentUser?.status && el.donorType === currentUser?.type)?.appointmentTypeId
      newBloodTiter = [currentUser?.bloodTiter]
      newDonorType = [currentUser?.donorType]
    }

    const startOverwriteDate = +chosenAppointment.value;
    const endOverwriteDate = startOverwriteDate + appointmentTypes.find(el => el.appointmentTypeId === newAppointmentType)?.duration;
    const exception = {
      from: timestampToIso(startOverwriteDate),
      to: timestampToIso(endOverwriteDate),
      date: timestampToIso(selectedTimeslot.partStart),
    }
    const updatedException = { ...timeSlotForOwerride, exceptionHoursOnDates: [...timeSlotForOwerride.exceptionHoursOnDates, exception], ...(recurrenceRule && { rule: recurrenceRule }), }

    const timeSlotForCreation = prepareTimeslotForCreation({
      appointmentTypeId: newAppointmentType,
      donorType: newDonorType,
      bloodTiter: newBloodTiter,
      startDate: startOverwriteDate,
      endDate: startOverwriteDate + appointmentTypes.find(el => el.appointmentTypeId === newAppointmentType)?.duration,
    }, selectedTimeslot)

    overwriteTimeslot(updatedException, timeSlotForCreation, bloodBankId, currentUser?.id, chosenAppointment.value)
  }

  const isOverwriteDisabled = currentUser === null
    || currentUser?.nextAppointment !== undefined
    || chosenAppointment === null
    || isInQuarantine;

  return (
    <>
      <Modal isModalOpen={bookAppointmentModalIsOpen} handleModalClose={handleModalClose} {...props}>
        <div className="modal-header">
          <h2 className="modal-title">
            {t('configuration.settings.bookAppointmentModal.header')}
          </h2>
          <button className="modal-close" onClick={handleModalClose}>
            <span className="icon-close" />
          </button>
        </div>

        <div className="modal-body modal-content">
          <div className="form-group d-flex items-center">
            <div className="flex-grow-0 modal-label">
              {t('configuration.settings.bookAppointmentModal.id')}
            </div>
            <div className="flex-grow-1">
              <Search
                type="text"
                placeholder={t('configuration.settings.bookAppointmentModal.searchPlaceholder')}
                shortSearch={true}
                setValue={setUserPnr}
                autoComplete={'off'}
                isPnrSearch={true}
              />
            </div>
          </div>

          <div className={`form-group modal-text ${currentUser || "thin"}`}>
            {currentUser ? `${currentUser.firstname} ${currentUser.lastname}` :
              ((userPnr === '') ? '' : (
                <>
                  <span>{t('configuration.settings.bookAppointmentModal.noMatch')}</span>
                  <span onCopy={handleCopy}>{` ${formatText(userPnr)}`}</span>
                </>
              )
              )
            }
          </div>

          {(currentAppointments.length > 1 && currentUser) &&
            <div className='form-group'>
              <CustomSelect
                label={t('configuration.settings.bookAppointmentModal.selectAppointmentTime')}
                data={currentAppointments}
                value={chosenAppointment}
                onChange={setChosenAppointment}
                isSearchable={true}
              />
            </div>
          }

          {currentUser && currentUser?.status !== selectedTimeslot?.appointmentType.donorStatus ?
            <div className="form-group modal-text thin d-flex justify-content-between">
              {t('configuration.settings.bookAppointmentModal.statusNotMatch')}
              <Button disabled={isOverwriteDisabled} onClick={toggleOverwriteModal} className="btn-flat" text={` +${t('configuration.settings.add')}`} />
            </div> :
            currentUser?.nextAppointment ?
              <div className="form-group modal-text thin">
                {t('configuration.settings.bookAppointmentModal.nextAppointment')}
              </div> :
              isTiterMatch ?
                <div className="form-group modal-text thin d-flex justify-content-between">
                  {t('configuration.settings.bookAppointmentModal.titerNotMatch')}
                  <Button disabled={isOverwriteDisabled} onClick={toggleOverwriteModal} className="btn-flat" text={` +${t('configuration.settings.add')}`} />
                </div> :
                isDonorTypeMatch ?
                  <div className="form-group modal-text thin d-flex justify-content-between">
                    {t('configuration.settings.bookAppointmentModal.donorTypeNotMatch')}
                    <Button disabled={isOverwriteDisabled} onClick={toggleOverwriteModal} className="btn-flat" text={` +${t('configuration.settings.add')}`} />
                  </div> :
                  isInQuarantine ?
                    <div className="form-group modal-text thin d-flex justify-content-between">
                      {t('configuration.settings.bookAppointmentModal.inQuarantine')}
                    </div> :
                    <></>
          }
        </div>

        <div className="modal-footer">
          <div className="inputs-group">
            <div className="inputs-group-item">
              <Button type="button" text={t('common.cancel')} onClick={toggleBookAppointmentModal} />
            </div>
            <div className="inputs-group-item">
              <Button
                disabled={
                  currentUser === null
                  || currentUser?.status !== selectedTimeslot?.appointmentType.donorStatus
                  || currentUser?.nextAppointment !== undefined
                  || chosenAppointment === null
                  || isTiterMatch
                  || isDonorTypeMatch
                  || isInQuarantine
                }
                btnClassName="btn-primary"
                type="button"
                text={t('common.book')}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </Modal>
      <OverWriteModal isModalOpen={isOverwriteModalOpened} handleClose={toggleOverwriteModal} onApply={handleOverwrite} isInProgress={isOverwriting} isError={overwritingError} />
    </>
  );
};

export default BookAppointment;
